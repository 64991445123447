// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, removeStorageData, setStorageData } from "framework/src/Utilities";
import { Color } from "@material-ui/lab/Alert";
import { apiCall } from "../../user-profile-basic/src/FooterController.web";
import { LaunchedCourses } from "../../landingpage/src/LandingPage.web";
import { updateWishlistCount } from "../../wishlist/src/WishlistController.web";
import React from "react";
export const configJSON = require("./config");

interface ResponseErrors {
  errors: {
    token: string;
  }[];
}

export interface AddCartResponse {
  message: string;
}

export interface CourseDetailResponse {
  meta: Meta;
  data: CourseDetailData;
  message: string;
}

export interface RelatedCoursesResponse {
  data: CourseDetailData[];
}

export interface WishlistResponse {
  meta?: {
    message: string;
  };
  message?: string;
}

export interface CourseDetailData {
  id: string;
  type: string;
  attributes: CourseDetailsAttributes;
}
export interface AddCourseAttributes  {
  all_course_class_duration: number;
  total_content: number;
  course_class_count: number;
  language_name: string;
  instructor_name: {
    name: string;
  };
  course_child_category: {
    color: string;
    name: string;
  };
  id: string;
  course_name: string;
  duration: string | null;
  year: string | null;
  created_at: string;
  updated_at: string;
  short_description: string;
  current_price: number | null;
  price: number | null;
  video_url: string | null;
  full_description: string;
  multiple_videos: any | null;
  course_category_id: number;
  valid_for_discount: boolean | null;
  course_sub_category_id: number;
  course_child_category_id: number;
  account_id: number;
  first_year_price: number;
  second_year_price: number;
  discounted_price: number;
  preview_video_url: string;
  what_learn: string[];
  status: boolean;
  trending: boolean;
  institute_id: number;
  language_options_language_id: number;
  tag: string | null;
  discounted_price_percentage: number | null;
  course_thumbnail: string;
  is_wishlist: string;
  is_purchased: boolean;
  is_cart: boolean;
  rating: number;
  student_count: number;
}
export interface CourseDetailsAttributes {
  course_count: number | string;
  total_duration: number;
  what_will_learn: string[];
  overview: string;
  bundle_course_thumbnail: string | undefined;
  rating_data: any;
  tag: string;
  rating:number;
  description: string;
  bundle_name: string;
  add_course: AddCourseAttributes[];
  course_name: string;
  short_description: string;
  full_description: string;
  account_id: number;
  first_year_price: number;
  second_year_price: number | null;
  discounted_price: number;
  preview_video_url: string;
  created_at: Date;
  updated_at: Date;
  status: boolean;
  trending: boolean;
  what_learn: string[];
  course_sub_category: CourseSubCategory;
  course_child_category: CourseChildCategory;
  created_on: string;
  student_count: number;
  course_category: CourseCategory;
  instructor_name: string;
  instructor_details: InstructorDetails;
  language_name: string;
  total_content: number;
  average_rating: AverageRating;
  specfifc_content_duration: SpecfifcContentDuration[];
  course_thumbnail: string;
  all_course_class_duration: number;
  is_wishlist: string;
  is_purchased: boolean;
  is_cart: boolean;
  course_class_count: number;
}

export interface AverageRating {
  id: number;
  tag?: string;
  course_name: string;
  duration: null;
  year: null;
  created_at: Date;
  updated_at: Date;
  short_description: string;
  current_price: null;
  price: null;
  video_url: null;
  full_description: string;
  multiple_videos: null;
  course_category_id: number;
  valid_for_discount: null;
  course_sub_category_id: number;
  course_child_category_id: number;
  account_id: number;
  first_year_price: number;
  second_year_price: null;
  discounted_price: number;
  preview_video_url: string;
  what_learn: string[];
  status: boolean;
  trending: boolean;
  institute_id: number;
  language_options_language_id: number;
  Rating: { [key: string]: number };
  Average_rating: number;
  total_rating_count: number;
  total_review_count: number;
}

export interface CourseCategory {
  id: number;
  name: string;
}

export interface CourseChildCategory {
  id: number;
  course_category_id: number;
  course_sub_category_id: number;
  name: string;
  total_courses: null;
  total_students: null;
  status: boolean;
  color: string;
  created_at: Date;
  updated_at: Date;
}

export interface CourseSubCategory {
  id: number;
  name: string;
  course_category_id: number;
  created_at: Date;
  updated_at: Date;
  status: boolean;
  total_courses: null;
  total_subjects: null;
}

export interface InstructorDetails {
  name: string;
  details: string;
  qualifications: string;
  leacture_in: string;
  profile_icon: string;
}

export interface SpecfifcContentDuration {
  id: number;
  course_id: number;
  name: string;
  details: string;
  status: boolean;
  no_of_classes: null;
  created_at: Date;
  updated_at: Date;
  course_chapter_thumbnail: string;
  course_learning_material: string;
  class_count: number;
  duration_count: number;
  classes_data: ClassesDatum[];
}

export interface ClassesDatum {
  id: number;
  course_chapter_id: number;
  title: string;
  details: string;
  class_type: string;
  type_url: null;
  duration: string;
  status: boolean;
  featured: boolean;
  created_at: Date;
  updated_at: Date;
  video_thumbnail: string;
  video_upload: string;
}

interface RatingObj {
  id: string,
  attributes: {
    rating: number,
    comment: string,
    status: boolean,
    name:string,
    user_name: {
      full_name: string
    }
  }
}

interface TestimonialListResponseObj{
    data: RatingObj[],
    meta: {
        message: string,
        page: string,
        total_testimonial: number
    }
}

interface TestimonialsAverageRatingResponseObj {
  total_average_rating: number,
  rated_user_count: number,
  one_rating: number,
  two_rating: number,
  three_rating: number,
  four_rating: number,
  five_rating: number,
  meta: {
    message: string
  }
}

export interface Meta {
  message: string;
}

const courseDetailTabs: unknown[] = [];

export interface Props {
  // Customizable Area Start
  navigation: {
    getParam: (param: string) => string;
    navigate: (value: string, options: { [key: string]: any }) => void;
  };
  id: string;
  classes: {
    WhatweLearnroot: string;
  title: string;
  item: string;
  checkbox: string;
  text: string;
  cardContainerMain: string;
  cardContainer: string;
  card: string;
  media: string;
  contentSection: string;
  cardChildCategories: string;
  starIconContainer: string;
  wishlistIconWrapperList: string;
  courseName: string;
  instructorContainer: string;
  instructorText: string;
  cardContent: string;
  titles: string;
  subtitle: string;
  details: string;
  hoverOverlay: string;
  hoverText: string;
  hoverCourseText: string;
  hoverDetails: string;
  courseDetails: string;
  CourseDescriptionContainer: string;
  DescriptionTitle: string;
  DescriptionSubTitle: string;
  bundleDetailsBtnDetails: string;
  avgRatingContainer: string;
  }
  // Customizable Area End
}

export interface APIPayload {
  contentType?: string;
  method: string;
  endPoint: string;
  apiBody?: object;
  body?: object;
  token?: string;
  type?: string;
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  isTrendingCoursesLoading: boolean;
  trendingCourses: LaunchedCourses[];
  pageNo: number;
  listMaxPerPage: number;
  lastPage: number | undefined;
  courseIndex: number;
  isCourseValidityOpen: boolean;
  courseValidityDetails: Record<string, any>;
  selectedValidity: number;
  userToken: string;
  isAlert: boolean;
  alertMsg: string;
  alertType: Color;
  courseDetail: CourseDetailsAttributes | undefined;
  add_course: AddCourseAttributes[];
  currentTab: number;
  tabs: unknown[];
  relatedCourses: CourseDetailData[];
  courseId: string;
  courseType: "course" | "bundle_course";
  openedAccordions: number[];
  isCourseVideoPlay: boolean;
  comeToBundle: boolean;
  isVideoError:boolean;
  ratingList: RatingObj[];
  totalAverageRating: number;
  ratedUserCount:number;
  ratingOne: number;
  ratingTwo: number;
  ratingThree: number;
  ratingFour: number;
  ratingFive: number;
  feedbackCount: number;
  totalTestmonials: number;
  ratingOneMaxValue: number;
  ratingTwoMaxValue: number;
  ratingThreeMaxValue: number;
  ratingFourMaxValue: number;
  ratingFiveMaxValue: number;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class UserProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCourseDetailApiCallId: string = "";
  getBundleCourseDetailsApiCallId: string = "";
  getRelatedCoursesApiCallId: string = "";
  getTrendingCoursesApiCallId: string = "";
  toggleWishlistApiCallId: string = "";
  addToChartApiCallId: string = "";
  getTestimonialsAverageRatingApiCallId: string = "";
  getTestimonialListApiCallId: string = "";

  protected stepInput: React.RefObject<HTMLInputElement>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationIdMessage)
    ];

    this.stepInput = React.createRef();

    this.state = {
      alertMsg: "",
      alertType: "success",
      isAlert: false,
      isLoading: true,
      userToken: "",
      courseDetail: undefined,
      add_course: [],
      relatedCourses: [],
      currentTab: 0,
      tabs: courseDetailTabs,
      courseId: "0",
      courseType: "course",
      openedAccordions: [],
      isCourseVideoPlay: false,
      comeToBundle: false,
      pageNo: 1,
      listMaxPerPage: 12,
      lastPage: undefined,
      isTrendingCoursesLoading: false,
      trendingCourses: [],
      courseIndex: 0,
      isCourseValidityOpen: false,
      courseValidityDetails: {},
      selectedValidity: 1,
      isVideoError: false,
      ratingList:[],
      totalAverageRating: 0,
      ratedUserCount: 0,
      ratingOne: 0,
      ratingTwo: 0,
      ratingThree: 0,
      ratingFour: 0,
      ratingFive: 0,
      feedbackCount: 1,
      totalTestmonials: 0,
      ratingOneMaxValue: 100,
      ratingTwoMaxValue: 100,
      ratingThreeMaxValue: 100,
      ratingFourMaxValue: 100,
      ratingFiveMaxValue: 100,
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    await this.handleUserToken();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson.errors) {
        switch (apiRequestCallId) {
          case this.getRelatedCoursesApiCallId:
            this.handleGetRelatedCoursesResponse(responseJson);
            break;
          case this.getCourseDetailApiCallId:
            this.handleGetDetailsResponse(responseJson);
            break;
          case this.getBundleCourseDetailsApiCallId:
            this.handleGetBundleDetailsResponse(responseJson);
            break
          case this.getTrendingCoursesApiCallId:
            this.handleTrendingCourses(responseJson);
            break;
          case this.toggleWishlistApiCallId:
            this.handleWishlistStatusResponse(responseJson);
            break;
          case this.addToChartApiCallId:
            this.handleChartStatusResponse(responseJson);
            break;
          case this.getTestimonialsAverageRatingApiCallId:
            this.handleTestimonialsAverageRatingResponse(responseJson);
            break; 
          case this.getTestimonialListApiCallId:
            this.handleTestimonialListResponse(responseJson);
            break; 
        }
      } else {
        this.parseErrors(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async handleUserToken() {
    const token = await getStorageData("loginToken");
    const courseId = this.props.navigation.getParam("courseId");
    this.setState({ userToken: token, courseId: courseId });
    if(window.location.pathname.includes("BundleCourseDetails")) {
      this.getBundleCourseDetails(courseId)
    } else {
    this.getCourseDetail(courseId);
    }
    this.getTrendingCourses(this.state.pageNo, this.state.listMaxPerPage);
    this.getTestimonialsAverageRating();
    const comeToBundle = await getStorageData("ComeToBundle");
    if(comeToBundle) {
      this.setState({ comeToBundle }, () => {
        removeStorageData("ComeToBundle")
      })
    }
  }

  parseErrors = async (responseJson: ResponseErrors) => {
    if (Object.keys(responseJson.errors[0])[0] === "token") {
      this.navigationLoginScreen();
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };

  navigationLoginScreen = () => {
    const messageLogin: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    messageLogin.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(messageLogin);
  };

  navigationHomeScreen = () => {
    const messageHome: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    messageHome.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(messageHome);
  };

  handleViewCourseList = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Categoriessubcategories");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationScreenNameMessage), { screenName: "Trending Courses" });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  };

  navigationShoppingCart = () => {
    const shoppingCartScreenMsg: Message = new Message(
      getName(MessageEnum.NavigationShoppingCartMessage)
    );
    shoppingCartScreenMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(shoppingCartScreenMsg);
  };

  handleTrendingCoursePage = (direction: "next" | "prev") => {
    const currentPage = this.state.pageNo + (direction === "next" ? 1 : -1);
    this.setState({ pageNo: currentPage }, () => {
      const totalPages = Math.ceil(this.state.trendingCourses.length / 4);
      const isLastPage = currentPage > totalPages;
      const totalPagesApi = Math.ceil(
        (isLastPage ? totalPages + 1 : totalPages) / 3
      );
      if (isLastPage) {
        this.getTrendingCourses(totalPagesApi, this.state.listMaxPerPage);
      }
    });
  };

  disabledNextButtons() {
    return (
      this.state.pageNo === this.state.lastPage ||
      this.state.isTrendingCoursesLoading
    );
  }

  disabledBackButtons() {
    return this.state.pageNo === 1 || this.state.isTrendingCoursesLoading;
  }

  navigateToPurchsedCourse = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "MyCourses");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message)
  }

  handleOpenCourseValidityPopup = (
    isAdd: boolean,
    course: any,
    list: "newLaunchCourses" | "trendingCourses" | "bundleCourses",
    index: number
  ) => {
    if (!this.state.userToken) {
      this.setState({
        alertMsg: "Please log in to add courses to your cart.",
        alertType: "info",
        isAlert: true
      });
      return;
    }
    if (isAdd) {
      this.setState({
        alertMsg: "Redirect to cart",
        isAlert: true,
        alertType: "info"
      });
      this.navigationShoppingCart();
      return;
    }
    this.setState(
      {
        courseIndex: index,
        courseValidityDetails: course
      },
      () => {
        this.setState({ isCourseValidityOpen: true });
      }
    );
  };

  async handleWishlistStatusResponse(response: WishlistResponse) {
    if(response.message) {
      this.setState({
        alertMsg: response.message,
        isAlert: true,
        alertType: "success"
      });
      await updateWishlistCount(false);
    }
    if (response && response.meta) {
      this.setState({
        alertMsg: response.meta.message,
        isAlert: true,
        alertType: "success"
      });
      await updateWishlistCount(true);
    }
  }

  stringToBoolean(value: string): boolean {
    return value.toLowerCase() === "true";
  }

  handleAddCourseWishlistStatus(isAdd: boolean, courseId: string) {
    this.handleWishlistStatusSharedLogic(isAdd, courseId, "add_course");
  }
  
  handleWishlistStatus(isAdd: boolean, courseId: string) {
    this.handleWishlistStatusSharedLogic(isAdd, courseId, "trendingCourses");
  }
  
  handleWishlistStatusSharedLogic(
    isAdd: boolean,
    courseId: string,
    stateKey: "add_course" | "trendingCourses"
  ) {
    if (!this.state.userToken) {
      this.setState({
        alertMsg: "Please log in to add courses to your wishlist.",
        alertType: "info",
        isAlert: true,
      });
      return;
    }
  
    const headers = {
      "Content-Type": !isAdd ? configJSON.apiContentType : undefined,
      token: this.state.userToken,
    };
  
    const body = {
      wishlist: {
        course_id: courseId,
      },
    };
    const formdata = new FormData();
    formdata.append("course_id", courseId);
    
    if (stateKey === "add_course") {
      let clone = [...this.state[stateKey]];
    const index = clone.findIndex((e) => e.id === courseId);
      clone[index].is_wishlist = `${!isAdd}`;
      this.setState({ [stateKey]: clone } as Pick<S, keyof S>);
    } else {
      let clone = [...this.state.trendingCourses];
      const index = clone.findIndex((e) => e.id === courseId);
      clone[index].attributes.is_wishlist = `${!isAdd}`;
      this.setState({ trendingCourses: clone } as Pick<S, keyof S>);
    }
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.toggleWishlistApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      !isAdd
        ? configJSON.wishlistApiEndpoint
        : configJSON.removeWishlistApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      !isAdd ? JSON.stringify(body) : formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      !isAdd ? configJSON.postMethod : configJSON.deleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async copyAndNavigate(url: string) {
    await this.copyTextToClipboard(window.location.href);
    window.open(url, "_blank");
  }

  async copyTextToClipboard(url: string) {
    try {
      const textToCopy = "URL copied to clipboard.";
      await navigator.clipboard.writeText(url || "");
      this.setState({
        isAlert: true,
        alertMsg: textToCopy,
        alertType: "success"
      });
    } catch (error) {
      this.setState({
        isAlert: true,
        alertMsg: "Can't copy code to clipboard",
        alertType: "error"
      });
    }
  }

  getLastPageIndex(arrayLength: number, elementsPerPage = 4) {
    const totalPages = Math.ceil(arrayLength / elementsPerPage);
    return totalPages;
  }

  handleAccordionChange(index: number) {
    const array = [...this.state.openedAccordions],
      findAccordion = array.findIndex(v => index === v);
    if (findAccordion > -1) {
      this.setState({
        openedAccordions: [
          ...this.state.openedAccordions.filter(v => v !== index)
        ]
      });
    } else {
      this.setState({
        openedAccordions: [...this.state.openedAccordions, index]
      });
    }
  }

  handleExpandAllAccordions(indexArray: number[]) {
    this.setState({
      openedAccordions:
        this.state.openedAccordions.length === indexArray.length
          ? []
          : indexArray
    });
  }

  handleTrendingCourses(responseJson: any) {
    if (!responseJson.data) {
      this.setState({
        pageNo: this.state.pageNo - 1,
        isTrendingCoursesLoading: false,
        lastPage: this.state.pageNo
      });
    } else {
      const arrayLength = responseJson.data.length;
      this.setState({
        trendingCourses: [...this.state.trendingCourses, ...responseJson.data],
        isTrendingCoursesLoading: false,
        lastPage:
          arrayLength < 12
            ? this.state.pageNo + this.getLastPageIndex(arrayLength)
            : undefined
      });
    }
  }

  handleCartStatusResponse(response: AddCartResponse) {
    if (response && response.message) {
      this.setState({
        alertMsg: response.message,
        isAlert: true,
        alertType: "success"
      });
    }
  }

  getTrendingCourses(pageNo: number, perPage: number) {
    this.setState({ isTrendingCoursesLoading: true });
    this.getTrendingCoursesApiCallId = apiCall({
      endPoint:
        configJSON.getTrendingCoursesWebApiEndPoint +
        `/?page=${pageNo}&per_page=${perPage}`,
      method: configJSON.getMethod,
      token: this.state.userToken
    });
  }

  getCourseDetail(courseId: string) {
    this.setState({ isLoading: true });
    this.getCourseDetailApiCallId = apiCall({
      endPoint: configJSON.getCourseDetailApiEndpoint + courseId,
      method: configJSON.getMethod,
      ...(this.state.userToken ? { token: this.state.userToken } : {})
    });
  }

  getBundleCourseDetails = (courseId: string) => {
    this.setState({ isLoading: true });
    this.getBundleCourseDetailsApiCallId = apiCall({
      endPoint: configJSON.getBundleCourseDetailsApiEndPoint + courseId,
      method: configJSON.getMethod,
      ...(this.state.userToken ? { token: this.state.userToken } : {})
    });
  }

  getRelatedCourse() {
    this.setState({ isLoading: true });
    this.getRelatedCoursesApiCallId = apiCall({
      endPoint:
        configJSON.getRelatedCoursesApiEndpoint +
        `?course_id=${this.state.courseId}&course_child_category_id=${
         this.state.courseType === "bundle_course" ? this.state.courseDetail?.add_course[0].course_sub_category_id  : this.state.courseDetail!.course_child_category.id
        }`,
      method: configJSON.getMethod,
      token: this.state.userToken
    });
  }

  handleGetRelatedCoursesResponse(responseJson: RelatedCoursesResponse) {
    if (responseJson && responseJson.data) {
      this.setState({
        relatedCourses: responseJson.data,
        isLoading: false
      });
    } else {
      this.setState({ isLoading: false });
    }
  }

  handleChartStatusResponse(response: AddCartResponse) {
    if (response && response.message) {
      this.setState({
        alertMsg: response.message,
        isAlert: true,
        alertType: "success",
        isCourseValidityOpen: false
      });
      this.state.courseValidityDetails.hasOwnProperty("redirect") && this.navigationShoppingCart();
    }
  }

  handleChartStatus(
    isCard: boolean,
    courseId: string,
    type: string,
    no_of_year: number = 1
  ) {
    const {courseType } = this.state;
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.userToken
    };
    const body = {
      course_id: courseId,
      no_of_year,
      text: courseType === "bundle_course" ?  "bundle_course" : type
    };

    if (isCard) {
      let clone = [...this.state.trendingCourses];
      const index = clone.findIndex(e => e.id === courseId);
      clone[index].attributes.is_cart = true;

      const stateToSet: unknown = { trendingCourses: clone };

      this.setState(stateToSet as Pick<S, keyof S>);
    } else {
      this.setState({
        courseDetail: { ...this.state.courseDetail!, is_cart: true }
      });
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addToChartApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addToChartApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  oncloseAlert() {
    this.setState({
      isAlert: false
    });
  }

  handleGetDetailsResponse(responseJson: CourseDetailResponse) {
    if (responseJson && responseJson.message) {
      this.navigationHomeScreen();
    } else if (responseJson && responseJson.data) {
      this.setState(
        {
          courseDetail: responseJson.data.attributes,
          courseType: responseJson.data.type as "course",
          isLoading: false
        },
        () => {
          this.getRelatedCourse()
          if(responseJson.data.attributes?.specfifc_content_duration[0]?.id) {
           this.handleAccordionChange(responseJson.data.attributes.specfifc_content_duration[0].id)
          }
        }
      );
    } else {
      this.setState({ isLoading: false });
    }
  }
  handleGetBundleDetailsResponse = (responseJson: CourseDetailResponse) => {
    if (responseJson && responseJson.message) {
      this.navigationHomeScreen();
    } else if (responseJson && responseJson.data) {
      this.setState(
        {
          courseDetail: responseJson.data.attributes,
          add_course: responseJson.data.attributes.add_course,
          courseType: responseJson.data.type as "bundle_course",
          isLoading: false
        },
        () => {
          this.getRelatedCourse()
        }
      );
    } else {
      this.setState({ isLoading: false });
    }
  }

  convertToTimeFormat(totalMinutes: number) {
    const totalSeconds = Math.floor(totalMinutes * 60);

    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);

    if (hours > 0) {
      let timeString = `${hours} hrs`;
      if (minutes > 0) {
        timeString += ` ${minutes} mins`;
      }
      return timeString.trim();
    } else {
      return `${minutes} mins`;
    }
  }

  convertToHoursAndMinutes(totalMinutes: number, isRight: boolean = false) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = Math.floor(totalMinutes % 60);
    if(isRight) {
      return `${hours.toString().padStart(2, '0')} hrs, ${minutes.toString().padStart(2, '0')} mins`;
    }else {
      return `${hours.toString().padStart(2, '0')} Hours, ${minutes.toString().padStart(2, '0')} Minutes`;
    }
  }

  onClose = () => {
    this.setState({ selectedValidity: 1, isCourseValidityOpen: false });
  };

  handleSelectValidity = (validity: 1 | 2) => {
    this.setState({ selectedValidity: validity });
  };

  handleCourseVideoPlay = () => {
    this.setState({ 
      isCourseVideoPlay: true
    })
  }
  truncateText = (text: string = "", length: number = 12) => {
    return text.length > length ? text.substring(0, length) + '...' : text;
  };
  getYouTubeVideoId = (url:any) => {
    const regex = /(?:https?:\/\/)?(?:www\.)?youtube\.com\/(?:embed\/|watch\?v=|v\/)?([^#&?]*).*/;
    const match = url.match(regex);
    return match && match[1] ? match[1] : null;
  };
  isYouTubeVideo = (url:any) => {
    return /(?:https?:\/\/)?(?:www\.)?youtube\.com\/(?:embed\/|watch\?v=|v\/)/.test(url);
  };
  
  handleVideoEnd = () => {
    this.setState({ isCourseVideoPlay: false });
  };
  
  isConditionalRending = (value: boolean , condition1: React.ReactNode , condition2: React.ReactNode) => {
    return value ? condition1 : condition2
  }

  btnHiddenRending = (condition: boolean, value: string , value2: string) => {
    return condition ? value : value2
  }

  getCourseChildCategoryName(item: { course_child_category?: { name?: string } }): string | undefined {
    return item.course_child_category?.name;
  }

  isORConditionRending = (value1: string | undefined | number, value2: string | number) => {
    return value1 || value2
  }

  getCourseValue(value1:string | number | JSX.Element, value2:string | number | JSX.Element) {
    const isBundleCourse = this.state.courseType === "bundle_course";
    return isBundleCourse ? value1 : value2
  }
  handleNavigateToCourseDetails = (courseId: string) => {
    setStorageData("ComeToBundle", "true")
    this.props.navigation.navigate("CourseDetails",  { courseId })
  }

  getTestimonialsAverageRating() {
    this.setState({ isLoading: true });
    this.getTestimonialsAverageRatingApiCallId = apiCall({
      endPoint: configJSON.getTestimonialsAverageRating + this.state.courseId,
      method: configJSON.getMethod,
      token: this.state.userToken
    });
  }

  handleTestimonialsAverageRatingResponse(response: TestimonialsAverageRatingResponseObj) {
    this.getTestimonialList(this.state.feedbackCount)
    if (response && response.meta.message) {
     this.setState({
      totalAverageRating: response.total_average_rating,
      ratedUserCount: response.rated_user_count,
      ratingOne: response.one_rating,
      ratingTwo: response.two_rating,
      ratingThree: response.three_rating,
      ratingFour: response.four_rating,
      ratingFive: response.five_rating,
      ratingOneMaxValue: this.getMaxValue(this.state.ratingOneMaxValue, response.one_rating),
      ratingTwoMaxValue: this.getMaxValue(this.state.ratingTwoMaxValue, response.two_rating),
      ratingThreeMaxValue: this.getMaxValue(this.state.ratingThreeMaxValue, response.three_rating),
      ratingFourMaxValue: this.getMaxValue(this.state.ratingFourMaxValue, response.four_rating),
      ratingFiveMaxValue: this.getMaxValue(this.state.ratingFiveMaxValue, response.five_rating),
     })
    }
  }

  getMaxValue(maxValue:number, value:number){
    if(maxValue == value){
      return maxValue + 10
    }
    return maxValue
  }

  getTestimonialList(feedbackCount: number) {
    this.getTestimonialListApiCallId = apiCall({
      endPoint: `${configJSON.getTestimmonialList}${this.state.courseId}&page=${feedbackCount}&per_page=20`,
      method: configJSON.getMethod,
      token: this.state.userToken
    });
  }

  getMoreTestimonialList() {
     let nextPageCount = this.state.feedbackCount;
     if(this.state.ratingList.length < this.state.totalTestmonials){
         nextPageCount = nextPageCount + 1;
         this.setState({feedbackCount: nextPageCount});
         this.getTestimonialList(nextPageCount)
     }
  }

  handleTestimonialListResponse(response: TestimonialListResponseObj) {
    const filteredData = response.data.filter(item => item.attributes.status === true);
    if (response && response.meta.message) {
      this.setState({
        ratingList: [...this.state.ratingList, ...filteredData],
        totalTestmonials: response.meta.total_testimonial
      })
    }
  }

  getRatingBoxVisibility(value: {rating: number, average_rating:{Average_rating: number}}){
   let ratingValue = this.state.courseType  === "bundle_course" ? value.rating : value.average_rating.Average_rating
   if(ratingValue > 0){
    return true
  }
  return false
  }
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
