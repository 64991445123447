import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Chip,
  withStyles,
  Theme,
  createStyles,
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
  styled,
  Card,
  LinearProgress
} from "@material-ui/core";
import { Close as CloseIcon, Check as CheckIcon } from "@material-ui/icons";
import { RiCheckboxMultipleFill } from "react-icons/ri";
import Skeleton from "@material-ui/lab/Skeleton";
import { Link } from "react-router-dom";
import parse, { HTMLReactParserOptions } from "html-react-parser";
import { MdCurrencyRupee } from "react-icons/md";
import CircleIcon from "@material-ui/icons/FiberManualRecord";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import {
  PiClipboardTextLight,
  PiGraduationCap,
  PiBookOpenText,
  PiUser,
  PiLockKeyFill,
  PiVideoFill
} from "react-icons/pi";
import { BsDot } from "react-icons/bs";
import {FaHeart, FaUnlockKeyhole, FaCirclePlay, FaCircleCheck ,FaPersonChalkboard} from "react-icons/fa6";
import NotificationAlertMessage from "../../../components/src/NotificationAlertMessage";
import CourseDetailController, {
  AddCourseAttributes,
  CourseDetailsAttributes,
  Props,
  configJSON
} from "./CourseDetailController.web";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { BiChalkboard } from "react-icons/bi";
import { IoLanguageSharp } from "react-icons/io5";
import { FiShare2 } from "react-icons/fi";
import { FaRegClock, FaStar } from "react-icons/fa";
import IconButton from "@material-ui/core/IconButton";
import Footer from "../../../components/src/Footer.web";
import {
  BundleCoursesAttributes,
  CustomDialog,
  LaunchedCourses,
  LaunchedCoursesAttributes,
  getNewLaunchCoursesWeb,
  CardType,
  webStyle
} from "../../landingpage/src/LandingPage.web";
import {
  courseDetailBg,
  facebookIcon,
  telegramICon,
  whatsappIcon,
  instagramIcon,
  clockIcon,
  backArrowPage,
  forwardArrowPage,
  lecturesIcon,
  noReviews,
  OvalBlue,
  YellowCircles
} from "./assets";
import HeaderWeb from "../../../components/src/Header.web";
import { LiaRupeeSignSolid } from "react-icons/lia";
import { HiMiniClock } from "react-icons/hi2";
import { star, } from "../../landingpage/src/assets";

let theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "'Inter', monospace"
    },
    body1: {
      fontFamily: "'Inter', monospace"
    }
  }
});
theme = responsiveFontSizes(theme);


const styles = createStyles({
  WhatweLearnroot: {
      backgroundColor: "#FFF8E1",
      padding: "24px",
      borderRadius: "8px",
      boxShadow: "0px 1px 8px 2px #00000012",
      minHeight: 200
  },
  title: {
      fontSize: "24px",
      fontWeight: "bold",
      marginBottom: "16px",
  },
  item: {
      display: "flex",
      alignItems: "center",
      marginBottom: "12px",
  },
  checkbox: {
      padding: 0,
      marginRight: "8px",
      color: "black",
      fontSize: 24,
      fontFamily: 'Inter'
  },
  text: {
      fontSize: "18px",
      fontFamily: 'Inter'
  },
  cardContainerMain: {
    textDecoration: 'none',
    minWidth: "314px",
  },
  cardContainer: {
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
      transition: "transform 0.3s ease, box-shadow 0.3s ease",
      borderRadius: '12px',
      overflow: 'hidden',
      padding: "12px",
      background: "#fff"
  },
  card: {
      position: "relative",
      borderRadius: "8px",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
      transition: "transform 0.3s ease, box-shadow 0.3s ease",
      "&:hover": {
          "& $hoverOverlay": {
              opacity: 1,
          },
      },
  },
  media: {
    height: 180,
    borderRadius: 8,
    aspectRatio: '16/9',
    objectFit: 'fill'
  },
  contentSection: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      marginTop: "8px",
      padding: "0px 8px",
      gap: "12px"
  },
  cardChildCategories: {
      borderBottom: `2px solid ${'blue'}`,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      '& p': {
          margin: 4,
          fontFamily: 'Inter'
      }
  },
  starIconContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    '& .ratingtext': {
      fontSize: "0.8rem",
      fontFamily: 'Inter',
      textDecoration: "none",
      color: '#000'
    }
  },
  wishlistIconWrapperList: {
      width: 30,
      height: 30,
      marginRight: 8,
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
  },
  courseName: {
      fontSize: 22,
      margin: '12px 0px',
      fontWeight: 600,
      fontFamily: 'Inter',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      wordBreak: 'break-word' as const,
      WebkitLineClamp: 2,
      display: "-webkit-inline-box",
      WebkitBoxOrient: "vertical" as const,
      textTransform: "capitalize" as const,
      hyphens: 'auto' as const,
      minHeight: 77
  },
  instructorContainer: {
      width: 'auto',
      backgroundColor: 'red',
      paddingLeft: 12,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
  },
  instructorText: {
    color: '#fff',
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: 18
  },
  cardContent: {
      padding: "16px",
  },
  titles: {
      fontSize: "16px",
      fontWeight: "bold",
      fontFamily: 'Inter',
      marginBottom: "8px",
  },
  subtitle: {
      fontFamily: 'Inter',
      fontSize: "14px",
      color: "#757575",
      marginBottom: "8px",
  },
  details: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      fontSize: "12px",
      color: "#757575",
  },
  hoverOverlay: {
      position: "absolute",
      top: 0,
      left: -4,
      width: "100%",
      height: "100%",
      background: "linear-gradient(180deg,rgba(1, 123, 147, 0.93) 0%,rgba(0, 33, 41, 0.97) 100%)",
      color: "#fff",
      opacity: 0,
      transition: "opacity 0.3s ease",
      borderRadius: "8px",
      padding: "8px 4px",
      display: "flex",
      flexDirection: "column",
      gap: 8
  },
  hoverText: {
    fontSize: "18px",
    fontWeight: 600,
    fontFamily: 'Inter',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  hoverCourseText: {
      fontFamily: 'Inter',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      wordBreak: 'break-word' as const,
      WebkitLineClamp: 4,
      display: "-webkit-inline-box",
      WebkitBoxOrient: "vertical" as const,
      textTransform: "capitalize" as const,
      hyphens: 'auto' as const
  },
  hoverDetails: {
      fontFamily: 'Inter',
      fontSize: "12px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: "50%",
      padding: '0px 8px',
      '& .detailsOptionText': {
        letterSpacing: "1px"
      }
  },
  courseDetails: {
      width: "100%",
      display: 'flex',
      justifyContent: "space-between",
      '& .courseInfoLeft': {
          width: "50%",
      },
      '& .info': {
          borderBottom: 0
      },
      '& .info1': {
          justifyContent: "unset",
          alignItems: 'center',
          flexDirection: 'row',
          gap: 12,
          padding: 0
      }
  },
  CourseDescriptionContainer: {
    background: 'rgba(245, 245, 245, 1)',
    borderRadius: 8,
    padding: theme.spacing(3),
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    margin: 'auto',
    width: "90%",
    border: '2px solid rgb(245, 245, 245)',
    minHeight: 320
  },
  DescriptionTitle: {
    fontWeight: 600,
    fontSize: '2rem',
    marginBottom: theme.spacing(2)
  },
  DescriptionSubTitle: {
    fontSize: '24px',
    color: '#333',
    lineHeight: 1.6,
    marginBottom: theme.spacing(2)
  },
  bundleDetailsBtnDetails: {
    "& .bundleBtnText": {
      fontWeight: 600
    },
    '& .detailIcon':{
      color: '#206575 !important'
    },
    '& .bundleAddedBtn': {
      borderRadius: "8px !important",
      background: "#FF5C01 !important",
      
    },
    '& .bundleAddToCart': {
      borderRadius: "8px !important",
      background: "#fff !important",
      '& .MuiTypography-caption': {
        display: 'none'
      }
    }
  },
  avgRatingContainer: {
    display: 'flex',              // Use flexbox
    justifyContent: 'center',
  }
});

const StyledTypography = styled(Typography)(() => ({
  fontFamily: 'Inter',
  width: "50%" ,
  textWrap: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden'
}))

const CustomBox = styled(Box)({
  height: 1120,
  overflowY: "auto",
  padding: '0px 8px',
  '&::-webkit-scrollbar': {
    width: 0,
    background: 'transparent'
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#578D92',
    borderRadius: '4px',
  },
})

const parserOptions: HTMLReactParserOptions = {
  replace: domNode => {
    if (domNode.type.toString() === "script") {
      return <></>;
    }
  }
};

const obj = [
  {
    label: "Chapters",
    icon: <PiBookOpenText fontSize={"inherit"} className="detailIcon" />,
    valueName: "total_content"
  },
  {
    label: "Lecture",
    icon: <BiChalkboard fontSize={"inherit"} className="detailIcon" />,
    valueName: "course_class_count"
  },
  {
    label: "Duration",
    icon: <FaRegClock fontSize={"inherit"} className="detailIcon" />,
    valueName: "all_course_class_duration"
  },
  {
    label: "Enrolled",
    icon: <PiGraduationCap fontSize={"inherit"} className="detailIcon" />,
    valueName: "student_count"
  },
  {
    label: "Languages",
    icon: <IoLanguageSharp fontSize={"inherit"} className="detailIcon" />,
    valueName: "language_name"
  }
];

// Customizable Area End

class CourseDetail extends CourseDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.closemodal = this.closemodal.bind(this);
    this.handleWishlistStatus = this.handleWishlistStatus.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  closemodal() {
    this.oncloseAlert();
  }

  showSkeleton(amount = 4) {
    return (
      <>
        {Array(amount)
          .fill(null)
          .map(() => (
            <Skeleton variant="rect" className="card" height={378} />
          ))}
      </>
    );
  }

  stringToHTML(htmlString: string) {
    return (
      <Typography
        variant="h6"
        className="description-wrapper"
        style={{
          margin: "16px 0",
          marginTop: 0,
          padding: 0,
          WebkitLineClamp: 3,
          height: 100,
          display: "-webkit-box",
          textOverflow: "ellipsis",
          overflow: "hidden",
          WebkitBoxOrient: "vertical"
        }}
        dangerouslySetInnerHTML={{
          __html: `${htmlString}`
        }}
      />
    );
  }

  stringToBoolean(value: string): boolean {
    return value.toLowerCase() === "true";
  }

  handleCoursesPagination = <T, _undefined>(
    array: T[],
    pageNumber: number,
    itemsPerPage: number
  ) => {
    const startIndex = (pageNumber - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return array.slice(startIndex, endIndex);
  };

  showCoursePrice(value: LaunchedCoursesAttributes | BundleCoursesAttributes) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          columnGap: "10px"
        }}
      >
        <Typography
          style={{
            display: "inline-flex",
            columnGap: "10px",
            alignItems: "center",
            fontWeight: "bold"
          }}
          variant="h5"
          component="h3"
          className="course-price"
        >
          <MdCurrencyRupee color="inherit" fontSize="inherit" />
          {Math.round(value?.discounted_price || 0)}/-
        </Typography>
        {value?.first_year_price ? (
          <Typography
            style={{
              display: "inline-flex",
              columnGap: "10px",
              alignItems: "center"
            }}
            className="course-price-discount"
            variant="body1"
          >
            <MdCurrencyRupee color="inherit" fontSize="inherit" />
            {Math.round(value.first_year_price)}/-
          </Typography>
        ) : null}
      </div>
    );
  }

  renderScreen = () => {
    const value = this.state.courseDetail as CourseDetailsAttributes,
      isPurchasedOrCart = value.is_purchased || value.is_cart, isPurchased = value.is_purchased;
      const { classes } = this.props;
      const { courseType } = this.state;
      const courseName = this.getCourseValue(value.bundle_name , value.course_name) 
      const descriptionShort = this.getCourseValue(value.overview , value.short_description);
      
    return (
      <>
        <Grid
          container
          style={{
            position: "relative",
            background: "rgba(60,116,129,1)",
            paddingTop: 32,
            height: 346
          }}
          spacing={3}
        >
          <StyledGrid
            item
            xs={12}
            style={{
              maxWidth: 1440,
              width: "100%",
              margin: "0 auto",
            }}
          >
            <Grid container style={{ position: "relative", height: '100%' }}>
              <Grid item xs={8} style={{ ...coursesStyles.column }}>
                <div style={coursesStyles.header}>
                  <Typography
                    variant="h3"
                    data-test-id="course-title"
                    style={{
                      margin: 0,
                      fontWeight: "bold",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      wordBreak: 'break-word' as const,
                      WebkitLineClamp: 2,
                      display: "-webkit-inline-box",
                      WebkitBoxOrient: "vertical" as const,
                      textTransform: "capitalize" as const,
                      hyphens: 'auto' as const
                    }}
                  >
                    {this.truncateText(String(courseName) , 60)}
                  </Typography>
                  {this.stringToHTML(String(descriptionShort))}
                  <Typography
                    variant="h6"
                    style={coursesStyles.smallDescription}
                  >
                    <Chip
                      label={
                        this.getCourseValue(value.tag, this.isORConditionRending(value.average_rating?.tag , value.course_child_category?.name))
                      }
                      style={{ background: "#ff5c01", color: "white" ,fontFamily: "Inter",
                        fontSize: "17.39px",
                        fontWeight: 700,
                        lineHeight: "21.05px",
                        letterSpacing: "-0.20170797407627106px",
                        textAlign: "left",
                        textTransform:"uppercase",

                        }}
                    />
                    <div>
                      Created by{" "}
                      <strong style={{ color: "#FCB910" }}>CHSE Express</strong>
                    </div>
                    {this.renderCourseDetailFaStar(value)}
                    <div
                      style={{
                        display: "inline-flex",
                        gap: 5,
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center", height: "2.25rem"}}>
                        <img src={clockIcon} height={18} width={18} />
                      </div>
                      <span data-test-id="hoursToMinutes">
                        {this.convertToHoursAndMinutes(
                          value.all_course_class_duration
                        )}
                      </span>
                    </div>
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={4} />
            </Grid>
          </StyledGrid>
        </Grid>
        <Grid
          container
          style={{
            position: 'relative',
            paddingTop: 0,
            width: "100%",
            margin: "0 auto",
            padding: "0 38px",
            display: "flex",
            justifyContent: "center"
          }}
          spacing={3}
        >
          <BgLeftBox />
          {/* <div style={{
            position: 'absolute',
            top: '150px',
            left: 0,
            background: `url(${OvalBlue})`,
            backgroundRepeat: 'no-repeat',
            width: '460px',
            height: '860px',
            '@media (min-width: 1366px) and (max-width: 1920px)': {
              width: '460px',
              height: '860px',
            },
            '@media (min-width: 600px) and (max-width: 1280px)': {
              width: '460px',
              height: '860px',
            },
            zIndex: -1,
          }} /> */}
            <BgRightBox />
            <div style={{ display: "flex", maxWidth: "1440px" }}>
          {courseType === "course" ? (
            <Grid item xs={8} style={{ ...coursesStyles.column , 
            height: 1190,
            padding: '12px 20px' }}>
            <StyledTabs
              value={this.state.currentTab}
              onChange={(_e, value) =>
                this.setState({
                  currentTab: value
                })
              }
              data-test-id="tabsTestId"
              aria-label="styled tabs example"
            >
              <StyledTab label="OVERVIEW" />
              <StyledTab label="COURSE" />
              <StyledTab label="INSTRUCTOR" />
              <StyledTab label="REVIEW" />
            </StyledTabs>
            <CustomBox className="CustomBoxContainer">
              {this.renderTab(value)}
            </CustomBox>
          </Grid>
          ) : (
            <>
             <Grid item xs={8} style={{
              ...coursesStyles.column,
              padding: '40px 20px'
          }}>
            {this.renderWhatLearn(value.what_will_learn)}
                <Box>
                  <Grid container spacing={3} style={{
                    maxHeight: 1120,
                    overflow: 'scroll',
                    margin: '24px auto',
                    scrollbarWidth: 'none',
                  }}>
                    {this.isConditionalRending(this.state.add_course && this.state.add_course.length > 0 , (
                      this.state.add_course.map(((item: AddCourseAttributes) => (
                        <Grid item
                          xs={12} sm={6} md={4} lg={4} 
                          data-test-id="add_course_card"
                          className={classes.cardContainerMain}
                          onClick={() => this.handleNavigateToCourseDetails(item.id)}
                          >
                          <Box className={classes.cardContainer}
                          >
                            <Card className={classes.card}>
                              <img
                                className={classes.media}
                                src={item.course_thumbnail}
                                title="Course Thumbnail"
                              />
                              <div style={webStyle.aboutCourse}>
                                <div className="course-cat-name" style={{
                                  maxWidth: 100,
                                  columnGap: 4,
                                  borderColor: item.course_child_category.color, 
                                  width: '42%',
                                  paddingLeft: "8px"
                                }}>
                                  <CircleIcon fontSize="inherit" htmlColor={item.course_child_category.color} />
                                  <Typography className="course-cat-name-text" variant="body2" component="strong">
                                    {this.getCourseChildCategoryName(item)}
                                  </Typography>
                                </div>
                                {item.rating > 0 ?
                                <div style={{
                                  display: "flex",
                                  textAlign: "center",
                                  columnGap: "8px",
                                  alignItems: "center",
                                  width: "45%",
                                  marginTop:'-10px'
                                }}>
                                  <img
                                    alt="rate"
                                    src={star}
                                    height={12}
                                    width={12}
                                  />
                                  <Typography className="student-count-rating" variant="body2" component="span">
                                    <Typography style={{ color: "black" }} variant="body2" component="strong">{item.rating}</Typography> | {value.student_count} Students
                                  </Typography>
                                </div>
                                 : <div style={{
                                  display: "flex",
                                  textAlign: "center",
                                  columnGap: "8px",
                                  alignItems: "center",
                                  width: "45%",
                                  marginTop:'-10px'
                                }}>
                                  <Typography className="student-count-rating" variant="body2" component="span" style={{marginLeft:20}}>{value.student_count} Students</Typography>
                                </div>}
                                <div
                                  className={classes.wishlistIconWrapperList}
                                >
                                  {this.isConditionalRending(item.is_wishlist === "true" , (
                                    <FaHeart
                                      className="heartIconList heartIconList2"
                                      style={{ fontSize: "1rem" }}
                                      color={"red"}
                                    />
                                  ) , (
                                    <FaHeart
                                      className="heartIconList heartIconList2"
                                      color={"#4C8491"}
                                      style={{ fontSize: "1rem" }}
                                    />
                                  ))}
                                </div>
                              </div>
                              <div style={{ marginLeft: "8px" }}>
                                <span className={classes.courseName}>{item.course_name}</span>
                              </div>
                              <div
                                className={classes.instructorContainer} style={{
                                  background: item.course_child_category.color
                                }}>
                                <Typography className={classes.instructorText}>{"Instructor"} - {item.instructor_name.name}</Typography>
                              </div>
                              <Box className={classes.hoverOverlay}>
                                <Box display={'flex'} justifyContent={'space-between'} padding={'0px 8px'}>
                                  <Box>
                                    <Typography style={{
                                      background: '#3C7481',
                                      padding: '4px 12px'
                                    }}>{item.course_child_category.name}</Typography>
                                  </Box>
                                  <Box>
                                    <div
                                      data-test-id="wishlistIcon"
                                      className={classes.wishlistIconWrapperList}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        this.handleAddCourseWishlistStatus(this.stringToBoolean(item.is_wishlist), item.id)
                                      }}>
                                      {this.isConditionalRending(item.is_wishlist === "true" , (
                                        <FaHeart
                                          className="heartIconList heartIconList2"
                                          style={{ fontSize: "1rem" }}
                                          color={"red"}
                                        />
                                      ) , (
                                        <FaHeart
                                          className="heartIconList heartIconList2"
                                          color={"#4C8491"}
                                          style={{ fontSize: "1rem" }}
                                        />
                                      ))}
                                    </div>
                                  </Box>
                                </Box>
                                <Box display={'flex'} flexDirection={"column"} style={{ gap: 12 ,padding: 8 }}>
                                <Typography className={classes.hoverCourseText} style={{
                                  WebkitLineClamp: 2,
                                  fontWeight: 600,
                                  fontSize: '24px',
                                }}>
                                  {item.course_name}
                                </Typography>
                                <Typography className={`${classes.hoverCourseText} ${classes.hoverText}`} style={{ textOverflow: 'ellipsis' }}>
                                  {item.short_description}
                                </Typography>
                                </Box>
                                <Box className={classes.hoverDetails}>
                                  <div className={classes.courseDetails}>
                                    <div className="courseInfoLeft">
                                    {item.rating > 0 ? 
                                    <Typography component="span" style={{ display: "flex", marginBottom: "10.55px", alignItems: 'center' }}>
                                    <img src={star} width={14} height={14} />
                                    <Typography component="span" style={{...webStyle.chapterLectureTimeText, fontSize: 14 }}>{item.rating} | {item.student_count} Students</Typography>
                                </Typography>
                                        : <Typography component="span" style={{ display: "flex", marginBottom: "10.55px", alignItems: 'center' }}>
                                          <Typography component="span" style={{ ...webStyle.chapterLectureTimeText, fontSize: 14 }}>{item.student_count} Students</Typography>
                                        </Typography>}
                                      <div>
                                        <div className="info1">
                                          <h3>{"Instructor"}</h3>
                                          <h3>{item.instructor_name.name}</h3>
                                        </div>
                                        <div className="info1">
                                          <h3>{"Language"}</h3>
                                          <h3>{item.language_name}</h3>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="courseInfoRight">
                                      <div className="info" style={{ paddingTop: 0 }}>
                                        <PiVideoFill className="infoIcon" color={"#fff"} />
                                        &nbsp;
                                        <h3 className="detailsOptionText">{item.total_content} {configJSON.chapter}</h3>
                                      </div>
                                      <div className="info">
                                        <img className="boardIcon" src={lecturesIcon} alt="a-lecture-board-icon" />
                                        &nbsp;
                                        <h3 className="detailsOptionText">{item.course_class_count} {configJSON.leactureText}</h3>
                                      </div>
                                      <div className="info">
                                        <HiMiniClock className="infoIcon" color={"#fff"} />
                                        &nbsp;
                                        <h3 className="detailsOptionText">{item.all_course_class_duration} {configJSON.minutes}</h3>
                                      </div>
                                    </div>
                                  </div>
                                </Box>
                              </Box>
                            </Card>
                          </Box>
                        </Grid>
                      )))
                    ) , null)}
                  </Grid>
                </Box>
                <Box>
                  <Box className={classes.CourseDescriptionContainer}>
                      <Typography className={classes.DescriptionTitle} variant="h5">
                          {configJSON.courseDescriptionTitle}
                      </Typography>
                      <Typography className={classes.DescriptionSubTitle}>
                         {value.description}
                      </Typography>
                  </Box>
              </Box>
          </Grid>
          </>
          )}
          
          <Grid
            item
            xs={4}
            ref={this.stepInput}
            style={{
              ...coursesStyles.column,
              marginTop: -240,
              gap: "1rem",
              zIndex: 6
            }}
          >
                <div className={courseType === "bundle_course" ? classes.bundleDetailsBtnDetails : ""} style={{...coursesStyles.rightColumnCard }}>
                <div style={{ position: "relative" }}>
  {this.state.isCourseVideoPlay ? (
    this.state.courseDetail?.preview_video_url && !this.state.isVideoError ?(
      this.isYouTubeVideo(this.state.courseDetail?.preview_video_url) ? (
        <iframe
          width="100%"
          height="100%"
          style={{
            borderRadius: "10.61px",
            aspectRatio: "16/9",
            display: "initial"
          }}
          className="imageContainer"
          src={`https://www.youtube.com/embed/${this.getYouTubeVideoId(
            this.state.courseDetail?.preview_video_url
          )}?autoplay=1`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      ) : (
        <video
          src={this.state.courseDetail?.preview_video_url}
          style={{
            borderRadius: "10.61px",
            height: "auto",
            width: "100%",
            aspectRatio: "16/9"
          }}
          controls
          autoPlay
          onEnded={this.handleVideoEnd}
          onError={()=>{this.setState({isVideoError:true})}}
        />
      )
    ) : (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          color: "#999",
          backgroundColor: "#f0f0f0",
          borderRadius: "10.61px",
          aspectRatio: "16/9"
        }}
      >
       No Preview Video Available
      </div>
    )
  ) : (
    <>
      <img
        data-test-id="course_thumbnail"
        src={value.course_thumbnail||value.bundle_course_thumbnail}
        style={{
          borderRadius: "10.61px",
          height: "auto",
          width: "100%",
          aspectRatio: "16/9"
        }}
        alt="course thumbnail"
        height={"auto"}
        width={"100%"}
        onClick={this.handleCourseVideoPlay}
      />
      <FaCirclePlay
        style={{
          position: "absolute",
          transform: "translate(-50%, -50%)",
          top: "50%",
          left: "50%",
          fontSize: 32,
          color: "white"
        }}
        width={39}
        height={39}
        onClick={this.handleCourseVideoPlay}
      />
    </>
  )}
</div>
              <div
                style={{
                  display: "inline-flex",
                  gap: 10,
                  alignItems: "center"
                }}
              >
                    <Typography variant="h4" style={{ width: "50%" , display: 'flex', alignItems: 'center'}}>
                  <MdCurrencyRupee fontSize={"inherit"} />{" "}
                  <strong style={{ color: "#00be10" }}>
                    {Math.round(value?.discounted_price || 0)}/-
                  </strong>
                </Typography>
                {value.first_year_price ? (
                  <Typography
                    variant="h6"
                    style={{
                      width: "50%",
                      color: "#BDBCBC"
                    }}
                  >
                    <div
                      style={{
                        textDecorationLine: "line-through",
                        textDecorationColor: "grey"
                      }}
                    >
                      <MdCurrencyRupee fontSize={"inherit"} />{" "}
                      {Math.round(value.first_year_price)}/-
                    </div>
                  </Typography>
                    ): null}
              </div>
              <div style={{ ...coursesStyles.column, gap: "10px", display: this.btnHiddenRending(this.state.comeToBundle , "none" , "flex")}}>
                <Button
                  variant="contained"
                  style={coursesStyles.containedButton}
                  className={"bundleAddedBtn"}
                  fullWidth
                  data-test-id="buyNowTestId"
                  onClick={() =>
                    !isPurchased ? this.handleOpenCourseValidityPopup(
                      isPurchasedOrCart,
                      {
                        attributes: this.state.courseDetail,
                        type: "course",
                        id: +this.state.courseId,
                        redirect: true
                      },
                      "trendingCourses",
                      +this.state.courseId
                    ) : this.navigateToPurchsedCourse()
                  }
                >
                  <Typography variant="h6" className={"bundleBtnText"} style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                    {this.isConditionalRending(isPurchased , "Go to my Courses" , "BUY NOW")}
                  </Typography>
                </Button>
                {!isPurchased &&
                  <Button
                    variant="outlined"
                    className="bundleAddToCart"
                    data-test-id="addToCartTestID"
                      style={{...coursesStyles.outlinedButton}}
                    fullWidth
                    onClick={() =>
                      !isPurchasedOrCart && this.handleOpenCourseValidityPopup(
                        isPurchasedOrCart,
                        {
                          attributes: this.state.courseDetail,
                          type: "course",
                          id: +this.state.courseId
                        },
                        "trendingCourses",
                        +this.state.courseId
                      )
                    }
                  >
                      <Typography variant="h6" className={"bundleBtnText"} style={{ display: 'flex', alignItems: 'center' , gap: 8 }}>
                      {this.isConditionalRending(isPurchasedOrCart , <FaCircleCheck color="inherit" fontSize="inherit" /> , <AddIcon color="inherit" fontSize="inherit" className="MuiTypography-caption" />)}
                      {this.isConditionalRending(isPurchasedOrCart
                        , "ADDED"
                        , "ADD TO CART")}
                    </Typography>
                  </Button>}
              </div>
              {this.courseDetailList(value)}
            </div>
            {this.renderRelatableCourses()}
          </Grid>
          </div>
        </Grid>
      </>
    );
  };

  renderRelatableCourses = () => {
    return (
      <>
      {this.state.relatedCourses &&
              this.state.relatedCourses.length ? (
              <div style={coursesStyles.rightColumnCard}>
                <Typography variant="h5" style={{ margin: 0 }}>
                  Related Courses
                </Typography>
                {this.state.relatedCourses.slice(0, 2).map(item => (
                  <Link
                    to={`/course/${item.id}`}
                    style={{
                      textDecoration: "none",
                      color: "black",
                      boxShadow: "0px 2px 6px 0px #00000046",
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                      borderRadius: 10.61,
                      padding: 12,
                      boxSizing: "border-box"
                    }}
                  >
                    <img
                      src={item.attributes.course_thumbnail}
                      style={{
                        borderRadius: "10.61px",
                        height: "180px",
                        width: "100%"
                      }}
                      alt="course thumbnail"
                      height={"180px"}
                      width={"auto"}
                    />
                    <Typography
                      variant="h6"
                      style={{
                        margin: 0,
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis"
                      }}
                    >
                      {item.attributes.course_name}
                    </Typography>
                    <Typography variant="subtitle1">
                      {item.attributes.course_class_count} chapters{" "}
                      <BsDot /> {item.attributes.total_content} lectures
                    </Typography>
                  </Link>
                ))}
              </div>
            ) : null}
      </>
    )
  }
  renderScreenLoading = () => {
    return (
      <>
        <div style={{
          display: 'flex',
          margin: "40px auto",
          maxWidth: "1440px",
          width: "100%",
          gap: "20px",
        }}>
          <div style={{ ...coursesStyles.column, ...coursesStyles.leftColumn, margin: '0px auto' }}>
            <div style={coursesStyles.header}>
              <h1
                style={{
                  margin: 0,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis"
                }}
              >
                <Skeleton variant="text" />
              </h1>
              <Skeleton variant="rect" width={"100%"} height={100} />
              <Skeleton variant="text" />
            </div>
            <Skeleton variant="rect" width={"100%"} height={350} />
          </div>
          <div
            style={{
              ...coursesStyles.column,
              ...coursesStyles.rightColumn
            }}
          >
            <div style={coursesStyles.rightColumnCard}>
              <Skeleton variant="rect" width={"100%"} height={400} />
            </div>
          </div>
        </div>
      </>
    );
  };

  renderTab(value: CourseDetailsAttributes) {
    switch (this.state.currentTab) {
      case 0: // Overview
        return this.renderOverview(value);
      case 1: // Course
        return this.renderCourse(value);
      case 2: // Instructor
        return  <div>
          <Typography
            variant="h6"
            style={{
              color: "#858585",
              fontWeight: "bold",
              margin: "15px 0"
            }}
          >
            Instructors
          </Typography>
          <div
            style={{
              display: "flex",
              gap: 10,
              width: "100%",
              boxSizing: "border-box",
              padding: 10,
              borderRadius: 12
            }}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr",
                width: "96%",
                gap: 16
              }}
            >
              <div
                style={{
                  display: "inline-flex",
                  width: "100%",
                  minHeight: 100,
                  gap: 20,
                  boxShadow: 'rgba(0, 0, 0, 0.145) 0px 1px 8px 0px',
                  background: '#fff',
                  alignItems: 'start',
                  padding: "8px 12px"
                }}
              >
                <div style={{ width: "20%", height: "auto", maxWidth: 200 }}>
                  <img
                    src={value.instructor_details.profile_icon}
                    style={{ width: "100%", height: "100%", borderRadius: 12 }}
                  />
                </div>
                <div style={{ width: "80%" }}>
                  <Typography variant="h6" style={{ margin: 0, fontFamily: 'Inter', fontSize: 28 }}>
                    {value.instructor_name}
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ margin: 0, fontWeight: "normal", fontFamily: 'Inter', fontSize: 20 }}
                  >
                    {value.instructor_details.leacture_in}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
      case 3: // Review
        return this.renderReview()
    }
  }

  renderCourse(value: CourseDetailsAttributes) {
    return (
      <div style={{ display: "flex", flexDirection: "column", width: "99%" }}>
        <Typography
          variant="h4"
          style={{ ...coursesStyles.titleText, marginBottom: 0 }}
        >
          Course Contents
        </Typography>
        <div
          style={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: 12
          }}
        >
          <Typography variant="h6">
            {value.total_content} Chapters <BsDot /> {value.course_class_count}{" "}
            Lectures
            <BsDot />{" "}
            {this.convertToHoursAndMinutes(
              value.all_course_class_duration
            )}{" "}
            Total Long
          </Typography>
          <Button
            endIcon={this.state.openedAccordions.length === value.specfifc_content_duration.map(e => e.id).length ? <ArrowDownwardIcon style={{ height: "1.2rem" }} htmlColor="#1721CD" /> :<ArrowForwardIcon htmlColor="#1721CD" />}
            variant="outlined"
            data-test-id="expandAllAccordions"
            style={coursesStyles.courseViewAll}
            onClick={() =>
              this.handleExpandAllAccordions(
                value.specfifc_content_duration.map(e => e.id)
              )
            }
          >
            <Typography variant="h6" style={coursesStyles.viewAllText}>
              {this.state.openedAccordions.length === value.specfifc_content_duration.map(e => e.id).length ? "COLLAPSE ALL" : "EXPAND ALL"}
            </Typography>
          </Button>
        </div>
        <div style={{ marginTop: 10 }}>
          {value.specfifc_content_duration.map(v => {
            const isExpanded = !!this.state.openedAccordions.find(
              x => x === v.id
            );
            return (
              <Accordion
                expanded={isExpanded}
                data-test-id="accordionTestId"
                onChange={() => this.handleAccordionChange(v.id)}
              >
                <AccordionSummary
                  expandIcon={
                    isExpanded ? (
                      <RemoveIcon htmlColor="white" />
                    ) : (
                      <AddIcon htmlColor="white" />
                    )
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  key={v.id}
                >
                  <Typography
                    component="h6"
                    style={{
                      fontWeight: "bold"
                    }}
                  >
                    {this.truncateText(`${v.name} ${v.name} ${v.name} ${v.name}`, 40)}
                  </Typography>
                  <Typography
                    component="span"
                    style={{ marginLeft: "auto", marginRight: 30 }}
                  >
                    {v.class_count} Classes
                  </Typography>
                  <Typography component="span">
                    {this.convertToTimeFormat(v.duration_count)}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  style={{ display: "flex", flexDirection: "column", gap: 12 , paddingTop: 24 }}
                >
                  <div
                    style={{ width: "100%", display: "inline-flex", gap: 16 }}
                  >
                    <img
                      src={v.course_chapter_thumbnail}
                      style={{
                        borderRadius: 10,
                        maxHeight: "136px",
                        width: "255px",
                        height: "auto",
                        aspectRatio: "16/9"
                      }}
                    />
                    <Typography style={{ margin: 0 }}>{v.details}</Typography>
                  </div>
                  <div
                    style={{ display: "flex", flexDirection: "column", gap: 5 }}
                  >
                    {v.classes_data.map((val, i) => (
                      <span
                        style={{
                          padding: "10px 5px",
                          borderBottom:
                            v.classes_data.length === i
                              ? ""
                              : "1px solid black",
                          display: "inline-flex",
                          alignItems: "center",
                          justifyContent: "space-between"
                        }}
                      >
                        <Typography
                          component="h6"
                          style={{
                            display: "inline-flex",
                            gap: 10,
                            alignItems: "center"
                          }}
                        >
                          {value.is_purchased ? (
                            <FaUnlockKeyhole fontSize={"32"} color="inherit" />
                          ) : (
                            <PiLockKeyFill fontSize={"32"} color="inherit" />
                          )}
                          <span style={{ fontSize: 25, fontFamily: 'Inter'}}>{val.title}</span>
                        </Typography>
                        <Typography component="h6" style={{ fontSize: 21, fontFamily: 'Inter' }}>
                          {this.convertToTimeFormat(parseFloat(val.duration))}{" "}
                        </Typography>
                      </span>
                    ))}
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      </div>
    );
  }

  renderWhatLearn(whatLearnList: string[]) {
    let { classes } = this.props;
    return (
      <Card className={classes.WhatweLearnroot}>
        <Typography className={classes.title}>{configJSON.whatWelearnTitle}</Typography>
        <Grid container spacing={3}>
          {whatLearnList && whatLearnList.length > 0 && whatLearnList.map((topic, index) => (
            <Grid item xs={6} key={index}>
              <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <RiCheckboxMultipleFill style={{ width: "50%", height: "20px", display: "flex", justifyContent: "center", alignItems: "center" }} />
                <Typography className={classes.text}
                  style={{ whiteSpace: 'normal', overflow: 'hidden', textOverflow: 'ellipsis', minWidth: '343px', maxWidth: 'auto', }}>
                  {topic}</Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Card>
    )
  }

  renderOverview(value: CourseDetailsAttributes) {
    return (
      <>
        <Typography variant="h4" style={coursesStyles.titleText}>
          Course Overview
        </Typography>
        <Box>
          <Typography
            className="AboutUsDescription"
            style={{ marginBottom: 8 }}
            dangerouslySetInnerHTML={{
              __html: `${value.full_description}`
            }}
          />
        </Box>
        {this.renderWhatLearn(value.what_learn)}
        <div
          style={{
            ...coursesStyles.column,
            ...coursesStyles.rightColumnCard,
            padding: 5,
            marginTop:'10px'
          }}
        >
          <div>
            <div
              style={{
                display: "inline-flex",
                gap: 10,
                width: "100%",
                boxSizing: "border-box",
                padding: 10,
                borderRadius: 12,
                background: "white",
              }}
            >
              <div style={{ position:"relative" }}>
                <img
                  src={value.course_thumbnail}
                  style={{
                    borderRadius: "10.61px",
                    height: "166px",
                    width: "266px"
                  }}
                  alt="course thumbnail"
                  height={"142px"}
                  width={"auto"}
                />
                <FaCirclePlay
                  style={{
                    position: "absolute",
                    transform: "translate(-50%, -50%)",
                    top: "50%",
                    left: "50%",
                    fontSize: 32,
                    color: "white"
                  }}
                  width={39}
                  height={39}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "75%"
                }}
              >
                <div style={coursesStyles.aboutCourse}>
                  <div
                    className="course-cat-name"
                    style={{
                      borderColor: value?.course_child_category?.color,
                      paddingBottom: 0,
                      maxWidth: "200px",
                      width: 'unset'
                    }}
                  >
                    <CircleIcon
                      fontSize="inherit"
                      htmlColor={value?.course_child_category?.color}
                    />
                    <Typography
                      variant="body2"
                      className="course-cat-name-text"
                    >
                      <strong style={{ fontSize: 18, fontFamily: 'Inter' }}>
                        {value.course_child_category &&
                          value.course_child_category.name}
                      </strong>
                    </Typography>
                  </div>
                  {value.average_rating.Average_rating > 0 ? 
                  <div
                    style={{
                      display: "flex",
                      textAlign: "center",
                      gap: "8",
                      alignItems: "center",
                      marginTop:'-5px'
                    }}
                  >
                    <FaStar fontSize={"inherit"} color="#FFD735" />
                    <Typography
                      variant="body2"
                      className="student-count-rating"
                      style={{
                        display: "inline-flex",
                        gap: 5,
                        alignItems: "center",
                        marginLeft: 12,
                        fontWeight: 600,
                        fontSize: 18,
                        fontFamily: 'Inter'
                      }}
                    >
                      <strong style={{ color: "black", }}>
                        {value.average_rating.Average_rating}
                      </strong>{" "}
                      | {value.student_count} Students
                    </Typography>
                  </div>
                  :   <div
                  style={{
                    display: "flex",
                    textAlign: "center",
                    gap: "8",
                    alignItems: "center",
                    marginTop:'-5px'
                  }}
                >
                  <Typography
                    variant="body2"
                    className="student-count-rating"
                    style={{
                      display: "inline-flex",
                      gap: 5,
                      alignItems: "center",
                      fontWeight: 600,
                      fontSize: 18,
                      fontFamily: 'Inter'
                    }}
                  >{value.student_count} Students
                  </Typography>
                </div>}
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                  <div>
                    <Typography variant="h6" style={{ marginTop: 10, fontFamily: 'Inter', fontSize: 24, fontWeight: 600 }}>
                      {value.course_name}
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      variant="h4"
                      component="h3"
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        // marginTop: 30
                      }}
                    >
                      <MdCurrencyRupee fontSize={"inherit"} />{" "}
                      <strong style={{ color: "#00be10" }}>
                        {Math.round(value?.discounted_price || 0)}/-
                      </strong>
                    </Typography>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </>
    );
  }

  findOutRatingFanStar(ratingStar: number, fontSize: number){
    let ratingStarArray = [];
    for (let index = 1; index < 6 ; index++) {
      if(index <= ratingStar){
      ratingStarArray.push({rating:index, type:'selected'})
      }else{
        ratingStarArray.push({rating:index, type:'unSelected'})
      }
    }
    return (
      <div style={{display:'flex', flexDirection:'row'}}>
        {ratingStarArray.map((item, index)=>{
          return(
            <div style={{ marginRight: "5px" }}>
              <FaStar fontSize={fontSize} color={item.type === 'selected' ? "#FFD735" : "#C9C9C9"} />
            </div>
          )
        })}
      </div>
    )
  }

  renderCourseDetailFaStar(value:{rating: number, average_rating: {Average_rating:number}}){
    if (this.getRatingBoxVisibility(value)) {
      return (
        <div style={{
          display: 'flex',
          alignItems: 'center',
          gap: '5px'
        }}>
          <FaStar color="#FFD735" />{" "}
          <span>{this.state.courseType === "bundle_course" ? value.rating : value.average_rating.Average_rating}</span>
        </div>
      )
    }
  }

  renderReview() {
    const { classes } = this.props;
    return (
      <MainFeedBackStyled>
        {this.state.ratingList.length > 0 && <Typography
          variant="h4"
          className="feedBackTitle"
        >
          Student Feedback
        </Typography>}
        <Box style={{backgroundColor:'#FFF',  flexDirection: 'column', display: 'flex', borderRadius: 23, boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', marginTop: 10 }}>
        {this.state.ratingList.length > 0 &&
        <Box style={{ flexDirection: 'row', display: 'flex', marginLeft:'50px', marginTop:'30px' }}>
          <Box style={{marginRight: '50px'}}>
            <Typography className="averageFeedBack">{`${this.state.totalAverageRating}/5`}</Typography>
            <Box className={classes.avgRatingContainer}>
              {this.findOutRatingFanStar(this.state.totalAverageRating, 30)}
            </Box>
            <Typography className="avgFeedBackDes">{`Based on ${this.state.ratedUserCount} Ratings & Reviews`}</Typography>
          </Box>
          <Box className="feedBackDivider" />
          <Box className="mainOveralRatingContainer">
            <Box className="innerOveralRatingContainer1">
              {this.findOutRatingFanStar(5, 15)}
              <BorderLinearProgress variant="determinate" value={(this.state.ratingFive / this.state.ratingFiveMaxValue) * 100} />
            </Box>
            <Box className="innerOveralRatingContainer2">
              {this.findOutRatingFanStar(4, 15)}
              <BorderLinearProgress variant="determinate" value={(this.state.ratingFour/ this.state.ratingFourMaxValue) * 100} />
            </Box>
            <Box className="innerOveralRatingContainer2">
            {this.findOutRatingFanStar(3, 15)}
              <BorderLinearProgress variant="determinate" value={(this.state.ratingThree/ this.state.ratingThreeMaxValue) * 100} />
            </Box>
            <Box className="innerOveralRatingContainer2">
            {this.findOutRatingFanStar(2, 15)}
              <BorderLinearProgress variant="determinate" value={(this.state.ratingTwo/ this.state.ratingTwoMaxValue) * 100} />
            </Box>
            <Box className="innerOveralRatingContainer2">
            {this.findOutRatingFanStar(1, 15)}
              <BorderLinearProgress variant="determinate" value={(this.state.ratingOne/ this.state.ratingOneMaxValue) * 100} />
            </Box>
          </Box>
        </Box>
       }
        <Box className="mainReviewListContainer">
        {this.state.ratingList.length > 0 ? 
          <Box className="innerReviewListContainer">
            {this.state.ratingList.map((item: {id: string, attributes:{rating: number,
      comment: string,name:string, user_name:{full_name: string}}}, index) => {
              return (
                <Box key={item.id} className="reviewListContainer">
                  <Typography className="reviewerName">{item.attributes.name}</Typography>
                   {this.findOutRatingFanStar(item.attributes.rating, 27)}
                  <Typography className="reviewerDes" style={{
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    WebkitLineClamp: 4
                  }}>{item.attributes.comment}</Typography>
                  {index != this.state.ratingList.length - 1 &&
                  <div style={{height:1, width:'98%', backgroundColor:'#C8C8C8', marginTop:'20px', marginBottom: '20px'}}/>
                  }
                  </Box>
              )
            })}
            {this.state.totalTestmonials > 20 && 
            <div style={{display:'flex', justifyContent:'center'}}>
            <Button data-test-id='btnLoadMoreFeedback' style={{alignSelf:'center'}} onClick={() => { this.getMoreTestimonialList() }}>
              <Typography className="viewMoreReviews">Show More</Typography>
            </Button>
            </div>
            }
          </Box>
          :
          <Box style={{display:'flex', justifyContent:'center', height:'100%'}}>
            <Box style={{display:'flex',flexDirection:'column', alignSelf:'center', alignItems:'center'}}>
            <img src={noReviews}  className="imgNoReviews" />
            <Typography className="noReviews">
            No Reviews
            </Typography>
            </Box>
            </Box>
        }

        </Box>
        </Box>
      </MainFeedBackStyled>
    );
  }

  renderView = () => {
    const trendingCoursesSort = this.handleCoursesPagination<
      LaunchedCourses,
      undefined
    >(this.state.trendingCourses, this.state.pageNo, 4);
    return (
      <div style={coursesStyles.mainLayout}>
        <Box sx={coursesStyles.screenLayout}>
          {!this.state.isLoading &&
            this.state.courseDetail &&
            this.renderScreen()}
          {this.state.isLoading && this.renderScreenLoading()}
          <div
            style={{
              maxWidth: '1440px',
              width: "100%",
              margin: '0px auto 35px auto'
            }}
          >
            <div
              style={{
                ...webStyle.launcedCourseHead,
                marginBottom: "33px",
                marginTop: 0
              }}
            >
              <Typography variant="h5" style={webStyle.courseTitle}>
                Trending Courses
              </Typography>
              <Button
                endIcon={<ArrowForwardIcon htmlColor="#1721CD" />}
                variant="outlined"
                data-test-id="goTrendingCourseList"
                style={webStyle.courseViewAll}
                onClick={() => this.handleViewCourseList()}
              >
                <Typography variant="subtitle1" style={webStyle.viewAllText}>
                  VIEW ALL
                </Typography>
              </Button>
            </div>
            <div style={webStyle.launchedCoursesMain}>
              <IconButton
                data-test-id="backTrendingCourse"
                onClick={() => this.handleTrendingCoursePage("prev")}
                style={{
                  display: this.state.pageNo === 1 ? "none" : ""
                }}
                className="change-page-button"
                disabled={this.disabledBackButtons()}
              >
                <img src={backArrowPage} width={48.56} height={48.56} />
              </IconButton>
              <div className="launchTrendCardMain">
                {trendingCoursesSort && trendingCoursesSort.length > 0
                  ? trendingCoursesSort.map(
                    (item: LaunchedCourses, index: number) => (
                      <React.Fragment key={item.id}>
                        {getNewLaunchCoursesWeb(
                          ({
                            ...item,
                            isAdd: true
                          } as unknown) as LaunchedCourses,
                          index,
                          CardType.trendingCourses,
                          this.stringToBoolean,
                          this.showCoursePrice,
                          this.handleWishlistStatus,
                          this.handleOpenCourseValidityPopup
                        )}
                      </React.Fragment>
                    )
                  )
                  : this.showSkeleton()}
              </div>
              <IconButton
                data-test-id="nextTrendingCourse"
                onClick={() => this.handleTrendingCoursePage("next")}
                className="change-page-button"
                style={{
                  ...webStyle.forwardIcon,
                  display:
                    this.state.pageNo + 1 === this.state.lastPage ? "none" : ""
                }}
                disabled={this.disabledNextButtons()}
              >
                <img src={forwardArrowPage} width={48.56} height={48.56} />
              </IconButton>
            </div>
          </div>
        </Box>
      </div>
    );
  };

  renderItemValidityDialog = () => {
    const {
      courseValidityDetails,
      selectedValidity,
      isCourseValidityOpen
    } = this.state;
    const courseDescription =  this.getCourseValue(courseValidityDetails.attributes.overview ,
      courseValidityDetails.attributes.short_description)
    const course_name = this.getCourseValue(courseValidityDetails.attributes.bundle_name , courseValidityDetails.attributes.course_name);
    return (
      <CustomDialog
        className={"dialogContainer"}
        open={isCourseValidityOpen}
        onClose={this.onClose}
        style={{ padding: 24 }}
      >
        <DialogTitle disableTypography className={"dialogTitleRoot"}>
          <Typography
            data-test-id="courseValidityTitle"
            className={"dialogTitle"}
          >
            {configJSON.courseValidityTitle}
          </Typography>
          <IconButton className={"closeButton"} onClick={this.onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={"dialogContent"}>
          <Typography variant="subtitle1" className={"subTitleCourseValidity"}>
            {course_name}: {courseDescription}
          </Typography>
          <div className={"buttonGroup"}>
            <Button
              data-test-id="1_year"
              variant="outlined"
              color="primary"
              className={`${"button"} ${
                selectedValidity === 1 ? "selected" : ""
                }`}
              onClick={() => this.handleSelectValidity(1)}
            >
              <span className={"buttonLabel"}>
                {selectedValidity === 1 && (
                  <CheckIcon className={"checkIcon"} />
                )}
                {configJSON.validity1Year}
                <div style={{
                  position: 'absolute' as const,
                  right: 0 , 
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: 600,
                  fontFamily: 'Inter',
                  fontSize: 24
                }}><LiaRupeeSignSolid className="rupeeIcon" /> {courseValidityDetails?.attributes?.first_year_price}</div>
              </span>
            </Button>
            {courseValidityDetails.attributes.second_year_price ? (
              <Button
                data-test-id="2_year"
                variant="outlined"
                color="primary"
                className={`${"button"} ${
                  selectedValidity === 2 ? "selected" : ""
                  }`}
                onClick={() => this.handleSelectValidity(2)}
              >
                <span className={"buttonLabel"}>
                  {configJSON.validity2year}
                  {selectedValidity === 2 && (
                    <CheckIcon className={"checkIcon"} />
                  )}
                  <div style={{
                    position: 'absolute' as const,
                  right: 0 , 
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: 600,
                    fontFamily: 'Inter',
                    fontSize: 24
                  }}><LiaRupeeSignSolid className="rupeeIcon" /> {courseValidityDetails.attributes.second_year_price}</div>
                </span>
              </Button>
            ) : null}
          </div>
        </DialogContent>
        <DialogActions
          style={{ justifyContent: "space-between", padding: "16px 24px" }}
        >
          <Button className={"cancelButton"} onClick={this.onClose}>
            {configJSON.cancelText}
          </Button>
          <Button
            data-test-id="applyButton"
            variant="contained"
            className={"applyButton"}
            onClick={() =>
              this.handleChartStatus(
                courseValidityDetails.hasOwnProperty("isAdd"),
                courseValidityDetails.id,
                courseValidityDetails.type,
                selectedValidity
              )
            }
          >
            {configJSON.applyText}
          </Button>
        </DialogActions>
      </CustomDialog>
    );
  };

  courseDetailList(value: CourseDetailsAttributes) {
    return (
      <>
        <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
        <div
              style={{
                display: "inline-flex",
                gap: 10,
                width: "100%",
                alignItems: "center",
                borderBottom: "1px solid #BEBEBE",
                padding: "5px 0"
              }}
            >
              <Typography
                variant="h6"
                style={{
                  display: "inline-flex",
                  width: "50%",
                  gap: "8px",
                  alignItems: "center",
                  fontWeight: "bold"
                }}
              >
                {this.getCourseValue(<FaPersonChalkboard fontSize={"inherit"} className="detailIcon" /> , <PiUser fontSize={"inherit"} />)}
                <strong>{this.getCourseValue("Courses", "Instructor")}</strong>
              </Typography>
              <StyledTypography variant="h6">
                {this.getCourseValue(value.course_count , value.instructor_name)}
              </StyledTypography>
            </div>
          {obj.map(item => (
            <div
              style={{
                display: "inline-flex",
                gap: 10,
                width: "100%",
                alignItems: "center",
                borderBottom: "1px solid #BEBEBE",
                padding: "5px 0"
              }}
            >
              <Typography
                variant="h6"
                style={{
                  display: "inline-flex",
                  width: "50%",
                  gap: "6px",
                  alignItems: "center",
                  fontWeight: "bold"
                }}
              >
                {item.icon}
                <strong>{item.label}</strong>
              </Typography>
              <StyledTypography variant="h6">
                {item.valueName === "all_course_class_duration"
                  ? this.convertToHoursAndMinutes(value[
                    (item.valueName as unknown) as keyof CourseDetailsAttributes
                  ] as number, true)
                  : value[
                  (item.valueName as unknown) as keyof CourseDetailsAttributes
                  ]}
              </StyledTypography>
            </div>
          ))}
          <div
            style={{
              display: "inline-flex",
              gap: 10,
              width: "100%",
              alignItems: "center",
              borderBottom: "1px solid #BEBEBE",
              padding: "5px 0"
            }}
          >
            <Typography
              variant="h6"
              style={{
                fontWeight: "bold",
                display: "inline-flex",
                alignItems: 'center',
                width: "50%",
                gap: "6px"
              }}
            >
              <FiShare2 fontSize={"inherit"} className="detailIcon" />
              <strong>{configJSON.shareOnText}</strong>
            </Typography>
            <div style={{ width: "50%", display: "inline-flex", gap: 5 }}>
              <img
                src={facebookIcon}
                width={20}
                height={20}
                alt="facebook logo"
                data-test-id="facebookNavigate"
                onClick={() =>
                  this.copyAndNavigate("https://www.facebook.com/")
                }
              />
              <img
                src={instagramIcon}
                width={20}
                height={20}
                alt="instagram logo"
                data-test-id="instagramNavigate"
                onClick={() =>
                  this.copyAndNavigate("https://www.instagram.com/")
                }
              />
              <img
                src={whatsappIcon}
                width={20}
                height={20}
                alt="whatsapp logo"
                data-test-id="whatsappNavigate"
                onClick={() =>
                  this.copyAndNavigate("https://web.whatsapp.com/")
                }
              />
              <img
                src={telegramICon}
                width={20}
                height={20}
                alt="telegram logo"
                data-test-id="telegramNavigate"
                onClick={() =>
                  this.copyAndNavigate("https://web.telegram.org/")
                }
              />
            </div>
          </div>
        </div>
        <Paper
          style={{
            marginTop: "10px",
            padding: "4px 5px",
            display: "flex",
            alignItems: "center",
            position: "relative",
            boxShadow: "0px 1px 6px rgba(0, 0, 0, 0.14)",
            borderRadius: 10,
            background: "#eee"
          }}
        >
          <InputBase
            style={{
              flex: 1,
              marginLeft: "10px",
              marginRight: "90px"
            }}
            readOnly
            value={window.location.href}
          />
          <Button
            onClick={() => this.copyTextToClipboard(window.location.href)}
            data-test-id="copyToClipboardTestId"
            style={{
              borderRadius: 7,
              background: "linear-gradient(133.48deg, #3cc9e1, #03859b)",
              height: 32,
              position: "absolute",
              right: 5,
              fontSize: 10,
              color: "white",
              display: "inline-flex"
            }}
            startIcon={<PiClipboardTextLight fontSize="inherit" />}
            variant="contained"
          >
            {configJSON.copyText}
          </Button>
        </Paper>
      </>
    );
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <NotificationAlertMessage
          dataTestId="alertTestId"
          alertType={this.state.alertType}
          msg={this.state.alertMsg}
          onClose={this.closemodal}
          isOpen={this.state.isAlert}
        />
        <HeaderWeb id={""} navigation={this.props.navigation} />
        {this.state.isCourseValidityOpen && this.renderItemValidityDialog()}
        <main
          style={{
            overflowX: "scroll",
            maxWidth: "1920px",
            margin: '0px auto',
            width: "100%"
          }}
        >
          <div
          >
            {this.renderView()}
            <Footer id="" navigation={this.props.navigation} />
          </div>
        </main>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    borderRadius: "20px !important",
    boxShadow: "none",
    margin: "16px 0"
  }
})(MuiAccordion);

export const AccordionSummary = withStyles({
  root: {
    flexDirection: "row-reverse",
    color: "white",
    background: "linear-gradient(310.05deg, #439EAD 7.85%, #206575 99.2%)",
    gap: 20,
    borderRadius: 20,
    transition: "0.3s ease",
    "&:hover": {
      filter: "brightness(1.2)"
    },
    "&$expanded": {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0
    }
  },
  expanded: {}
})(MuiAccordionSummary);

const StyledGrid = styled(Grid)(() => ({
  padding: '0px',
  '@media (min-width:1080px) and (max-width:1440px)': {
    padding: '0px 55px !important'
  }
}))

enum CourseStylesProperties {
  smallDescription = "smallDescription",
  screenLayout = "screenLayout",
  mainLayout = "mainLayout",
  leftColumn = "leftColumn",
  rightColumn = "rightColumn",
  rightColumnCard = "rightColumnCard",
  column = "column",
  header = "header",
  outlinedButton = "outlinedButton",
  containedButton = "containedButton",
  aboutCourse = "aboutCourse",
  courseViewAll = "courseViewAll",
  viewAllText = "viewAllText",
  titleText = "titleText",
}
const coursesStyles: Record<CourseStylesProperties, React.CSSProperties> = {
  titleText: {
    color: "#858585",
    fontWeight: 700,
    marginTop: 15,
    marginBottom: 15
  },
  viewAllText: {
    color: "#1220C1",
    fontFamily: "Inter",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.3px"
  },
  courseViewAll: {
    display: "flex",
    columnGap: "10px",
    borderRadius: "31.826px",
    border: "1.8px solid #1721CD",
    background: "#FFF"
  },
  aboutCourse: {
    display: "flex",
    columnGap: "11px",
    width: "100%",
    padding: "0px 8px",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    color: "white",
    height: "100%",
    justifyContent: "space-between",
    padding: "24px 0px"
  },
  screenLayout: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  mainLayout: {
    width: "100%",
    boxSizing: "border-box"
  },
  column: { display: "flex", flexDirection: "column" },
  leftColumn: {
    width: "70%",
    gap: "19px"
  },
  rightColumn: {
    width: "30%",
    gap: "30px"
  },
  rightColumnCard: {
    boxSizing: "border-box",
    padding: 15,
    borderRadius: 12,
    background: "white",
    boxShadow: "0px 1px 8px 0px #00000025",
    gap: "10px",
    display: "flex",
    flexDirection: "column"
  },
  smallDescription: {
    display: "inline-flex",
    gap: 30,
    alignItems: "center",
    margin: "10px 0"
  },
  outlinedButton: {
    background: "white",
    color: "#FD7101",
    borderRadius: "32px",
    border: "2px solid #FD7101",
    boxShadow: "0"
  },
  containedButton: {
    background: "linear-gradient(133.48deg, #FF5C01 -26.6%, #FFA302 118.85%)",
    color: "white",
    borderRadius: "32px",
    boxShadow: "0"
  }
};

interface StyledTabsProps {
  value: number;
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}

const StyledTabs = withStyles({
  fixed: {
    height: "58px"
  },
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      borderRadius: "12px",
      width: "100%",
      backgroundColor: "#ffd735"
    }
  }
})((props: StyledTabsProps) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />
));

interface StyledTabProps {
  label: string;
}

const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '24%',
      textTransform: "none",
      background: "#e3e3e3",
      color: "#212121",
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(23),
      marginRight: theme.spacing(1),
      padding: "6px 40px",
      borderRadius: "0px 0px 12px 12px",
      "@media (min-width: 900px) and (max-width: 1280px)": {
        padding: "6px 16px",
        fontSize: 16
      },
      "@media (min-width: 1280px) and (max-width: 1419px)": {
        padding: "6px 24px",
      },
      "&.Mui-selected": {
        background: "linear-gradient(180deg, #ffed65, #fcb910)",
        fontWeight: 600
      },
      "&:focus": {
        opacity: 1
      }
    }
  })
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  width: '100%',
  height: 8,
  borderRadius: 5,
  marginLeft: '10px',
  alignSelf: 'center',
  '&.MuiLinearProgress-colorPrimary': {
    backgroundColor: theme.palette.grey[100],
  },
  '& .MuiLinearProgress-bar': {
    borderRadius: 5,
    backgroundColor: '#FF8256',
  },
}));

const BgLeftBox = styled(Box)(()=>({
    position: 'absolute',
    top: '150px',
    left: 0,
    background: `url(${OvalBlue})`,
    backgroundRepeat: 'no-repeat',
    width: '460px',
    height: '860px',
    '@media (min-width: 1366px) and (max-width: 1920px)': {
      width: '460px',
      height: '860px',
    },
    '@media (min-width: 600px) and (max-width: 1280px)': {
      width: '260px',
      height: '460px',
    },
    zIndex: -1,
}));

const BgRightBox = styled(Box)(()=>({
  position: 'absolute',
  bottom: 0,
  right: 0,
  background: `url(${YellowCircles})`,
  backgroundRepeat: 'no-repeat',
  width: '860px',
  height: '1078px',
  '@media (min-width: 1366px) and (max-width: 1920px)': {
    width: '860px',
  height: '1078px',
  },
  '@media (min-width: 600px) and (max-width: 1280px)': {
    width: '660px',
  height: '1078px',
  },
  zIndex: -1,
}))

const MainFeedBackStyled = styled(Box)(() => ({
  display: 'flex', flexDirection: "column", 
  '& .feedBackTitle' :  {
    color: "#858585",
    fontWeight: 700,
    marginTop: 15,
    marginBottom: 15,
  },
  '& .averageFeedBack':  {
    color: "#212121",
    fontFamily: 'Inter',
    fontSize: 33,
    fontWeight: 700,
    marginTop: 15,
    marginBottom: 15,
    textAlign: 'center',
    margin:'40px 5px 20px 10px',
    '@media (min-width: 1366px) and (max-width: 1920px)': {
      fontSize: 33,
    },
    '@media (min-width: 600px) and (max-width: 1280px)': {
      fontSize: 22,
    },
  },
  '& .avgFeedBackDes':{
    color: "#585858",
    fontFamily: 'Inter',
    fontSize: 20,
    fontWeight: 400,
    marginTop: 15,
    marginBottom: 15,
    textAlign: 'center',
    '@media (min-width: 1366px) and (max-width: 1920px)': {
      fontSize: 20,
    },
    '@media (min-width: 600px) and (max-width: 1280px)': {
      fontSize: 18,
    },
  },
  '& .feedBackDivider':{width:'1px', height:'170px', backgroundColor:'#BEBEBE', alignSelf:'center'},
  '& .mainOveralRatingContainer':{display: 'flex', flexDirection: 'column', width: '100%', marginTop: '15px', marginLeft:'50px' },
  '& .innerOveralRatingContainer1':{display: 'flex', width: '50%', flexDirection: 'row'},
  '& .innerOveralRatingContainer2': { display: 'flex', width: '50%', flexDirection: 'row', marginTop: '20px' },
  '& .mainReviewListContainer': { height:'80vh',
    margin:'40px 5px 20px 10px',
    '@media (min-width: 1366px) and (max-width: 1920px)': {
      height:'70vh'
  },
  '@media (min-width: 600px) and (max-width: 1200px)': {
      height:'80vh'
  },},
  '& .innerReviewListContainer' : { height:'100%', overflowY: 'auto'},
  '& .reviewListContainer': {margin: '10px 5px 10px 20px'},
  '& .reviewerName': {
    color: "#212121",
    fontFamily: 'Inter',
    fontSize: 24,
    fontWeight: 700,
    marginBottom:'10px',
    '@media (min-width: 1366px) and (max-width: 1920px)': {
      fontSize: 27,
    },
    '@media (min-width: 600px) and (max-width: 1200px)': {
      fontSize: 24,
    },
    '@media (min-width: 1200px) and (max-width: 1366px)':{
      fontSize: 24,
    },
  },
  '& .reviewerDes': {
    color: "#585858",
    fontFamily: 'Inter',
    fontSize: 27,
    fontWeight: 400,
    marginTop:'10px',
    marginRight:'10px',
    '@media (min-width: 1200px) and (max-width: 1366px)':{
      fontSize: 24,
    },
    '@media (min-width: 1366px) and (max-width: 1920px)': {
      fontSize: 27,
    },
    '@media (min-width: 600px) and (max-width: 1200px)': {
      fontSize: 24,
    }
  },
  '& .viewMoreReviews': {
    color: "#3784F8",
    fontFamily: 'Inter',
    fontSize: 20,
    fontWeight: 400,
    textAlign: 'center',
    '@media (min-width: 1366px) and (max-width: 1920px)': {
      fontSize: 20,
    },
    '@media (min-width: 600px) and (max-width: 1200px)': {
      fontSize: 18,
    }
  },
  '& .imgNoReviews':{
    height:125, width:125,
    '@media (min-width: 1366px) and (max-width: 1920px)': {
      height:125, width:125,
    },
    '@media (min-width: 600px) and (max-width: 1200px)': {
      height:123, width:123,
    }
  },
  '& .noReviews': {
    color: "#212121",
    fontFamily: 'Inter',
    fontSize: 37,
    fontWeight: 400,
    textAlign: 'center',
    alignSelf:'center',
    '@media (min-width: 1366px) and (max-width: 1920px)': {
      fontSize: 37,
    },
    '@media (min-width: 600px) and (max-width: 1200px)': {
      fontSize: 35,
    }
  }
}))

export default withStyles(styles)(CourseDetail);
export { CourseDetail }
// Customizable Area End
