import React from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  IconButton,
  Dialog,
  DialogContent,
  Typography,
  CssBaseline,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import ArrowBack from '@material-ui/icons/ArrowBack';

interface IModalProps {
  open: boolean;
  onClose?: () => void;
  onBack?: null | (() => void);
  customStyles?: { modal: {} };
  dataTest?: string;
  isCloseIcon?: boolean;
  dialogTitle?: string; 
}

const theme = createTheme({
  typography: {
    h1: {
      fontSize: "18px",
      fontWeight: 700,
      lineHeight: "26px",
      marginBottom: "16px",
    },
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: "#0F172A",
        opacity: "0.4 !important",
      },
    },
    MuiDialog: {
      paper: {
        width: "651px !important",
        maxWidth: "651px !important"
      }
    },
    MuiDialogContent: {
      root: {
        boxSizing: 'border-box',
        "&:first-child": {
          padding: "34px 24px !important",
        },
        background: '#FFF',
        boxShadow: '0px 25px 50px 0px rgba(0, 0, 0, 0.09), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 32px 0px rgba(0, 0, 0, 0.06)',
        borderRadius: '18px',
      },
    },
    MuiPaper: {
      root: {
        borderRadius: '18px !important',
      },
    },
    MuiCssBaseline: {
      "@global": {
        /* Scrollbar for WebKit browsers */
        "::-webkit-scrollbar": {
          width: "5px",
        },
        "::-webkit-scrollbar-track": {
          background: "#f1f1f1",
          borderRadius: "10px",
        },
        "::-webkit-scrollbar-thumb": {
          background: "#888",
          borderRadius: "10px",
        },
        "::-webkit-scrollbar-thumb:hover": {
          background: "#555",
        },
        /* Scrollbar for Firefox */
        "*": {
          scrollbarWidth: "thin",
          scrollbarColor: "#888 #f1f1f1",
        },
      },
    },
  },
});

const webStyle = {
  dialog: {
    fontFamily: '"Inter", sans-serif', // '"Josefin Sans", sans-serif',
  },
};

const GenericModal: React.FC<IModalProps> = ({ children, dataTest = 'generic-modal', open, onClose, onBack = null, isCloseIcon = true, customStyles = { modal: {} }, dialogTitle="" }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Dialog fullWidth open={open} onClose={onClose} data-test-id={dataTest} style={{...webStyle.dialog,}}>
        <DialogContent style={{...customStyles.modal}}>
          {dialogTitle && <Typography variant="h1" style={{ position: "relative" }}>
            {dialogTitle}
            {isCloseIcon && <IconButton style={{ position: "absolute", right: 0, top: 0, color: '#0F172A', padding: "0px" }} aria-label="close" onClick={onClose}>
              <CloseIcon />
            </IconButton>}
          </Typography>}
          {!!onBack && (
            <IconButton style={{ position: "absolute", left: 12, top: 22, color: '#0F172A' }} aria-label="back" onClick={onBack}>
              <ArrowBack />
            </IconButton>
          )}
          <div>
            {children}
          </div>
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
};

export default GenericModal;