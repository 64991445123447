import firebase from "firebase/app";
import "firebase/messaging";
import { removeStorageData, setStorageData } from "../../framework/src/Utilities";

const firebaseConfig = {
    apiKey: "AIzaSyDlLEqEYT-kPIukB4nRMKfUUdxRCTiaLjY",
    authDomain: "chse-express-6f47f.firebaseapp.com",
    projectId: "chse-express-6f47f",
    storageBucket: "chse-express-6f47f.firebasestorage.app",
    messagingSenderId: "786597594690",
    appId: "1:786597594690:web:5735e2056da80d71af4fdb",
    measurementId: "G-6RB56LWXS2"
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);

// Get messaging instance
const messaging = firebaseApp.messaging();

// Request notification permission
export const requestPermission = async () => {
    try {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
            console.log("Notification permission granted.");
            const token = await messaging.getToken();
            console.log("FCM Token:", token);
            setStorageData("fcmtoken", token);
        } else {
            console.log("Notification permission denied.");
            removeStorageData("fcmtoken");
        }
    } catch (error) {
        console.error("Error getting FCM token:", error);
    }
};

// Listen for foreground messages
export const onMessageListener = () =>
    new Promise((resolve) => {
        messaging.onMessage((payload) => {
            console.log("Message received. ", payload);
            resolve(payload);
        });
    });

// Register service worker
export const registerServiceWorker = async () => {
    if ("serviceWorker" in navigator) {
        try {
            const registration = await navigator.serviceWorker.register("/firebase-messaging-sw.js");
            console.log("Service Worker registered:", registration);
        } catch (error) {
            console.error("Service Worker registration failed:", error);
        }
    }
};
