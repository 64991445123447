import React from "react";

// Customizable Area Start
import { IBlock } from "../../framework/src/IBlock";
import { Message } from "../../framework/src/Message";
import { BlockComponent } from "../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";
export const configJSON = require("../../blocks/landingpage/src/config");
const defaultProfilePic =
  'https://t3.ftcdn.net/jpg/05/16/27/58/360_F_516275801_f3Fsp17x6HQK0xQgDQEELoTuERO4SsWV.jpg';
import moment from 'moment';
import { differenceInDays, parseISO, format } from 'date-fns';
import { registerServiceWorker, requestPermission } from "./firebase-config";
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  cartCounts?:number;
  wishlistCounts?:number;
  searchText?: string;
  // Customizable Area End
}

export interface APIPayload {
  contentType?: string;
  method: string;
  endPoint: string;
  apiBody?: object;
  body?: object;
  token?: string;
  type?: string;
}

export interface FooterResponse {
  data: FooterData;
  errors: any[];
  message: string;
}

export interface FooterData {
  address: string;
  full_phone_number: string;
  email: string;
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  userToken: string;
  username: string;
  profilePic: string;
  cartCount: number;
  wishlistCount: number;
  search: string;
  isDropdownVisible: boolean;
  isHovering:boolean;
  notificationList:any;
  currentPage: number;
  hasMore: boolean;
  hasUnread:boolean;
  totaPages:number;
  per_page:number;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class HeaderController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getFooterListApiCallId: string = "";
  notificationId:string="";
  readNotificationId:string="";
  hasUnreadId:string='';
  dropdownRef: React.RefObject<HTMLDivElement>;
  addDeviceIdApiCallId: string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationIdMessage)
    ];

    this.state = {
      isLoading: false,
      userToken: "",
      username: 'USERNAME_TESTING_PURPOSE_ONLY',
      profilePic: defaultProfilePic,
      cartCount: 0,
      wishlistCount: 0,
      search: "",
      isDropdownVisible: false,
      isHovering:false,
      notificationList:[],
    currentPage: 1,
    hasMore: true,
    hasUnread:false,
    totaPages:0,
    per_page:10
    };
    this.dropdownRef = React.createRef();
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    window.addEventListener('storage', this.handlerFetch);
    window.addEventListener('cartCountChange', this.handleCartCountChange as EventListener);
    window.addEventListener('wishlistCountChange', this.handleWishlistCountChange as EventListener);
    window.addEventListener("mousedown", this.handleClickOutside);
    await this.fetchProfilePic();
    const token = await getStorageData("loginToken");
    this.setState({ userToken: token });
    const cartCount = await getStorageData('cartCount');
    const wishlistCount = await getStorageData('wishlistCount');
    if(cartCount) {
      this.setState({ cartCount })
    }
    if(this.props.cartCounts) {
      this.setState({ cartCount : this.props.cartCounts })
    }
    if (wishlistCount) {
      this.setState({ wishlistCount: wishlistCount});
    }
    if (this.props.wishlistCounts) {
      this.setState({ wishlistCount: this.props.wishlistCounts});
    }
    if(this.props.searchText){
      this.setState({ search: this.props.searchText });
    }
    if(this.state.userToken){
      this.notficationApi()
      this.hasUnreadMsg()
      this.handleNotificationPermission();
    }
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(apiRequestCallId==this.notificationId){
        if(responseJson.data){        
        const newNotifications = responseJson.data; // Adjust this based on actual response structure.
        
        this.setState((prevState) => ({
          notificationList: [...prevState.notificationList, ...newNotifications],
          currentPage: prevState.currentPage + 1,
          hasMore: newNotifications.length === prevState.per_page, // If fewer than `per_page` items, no more pages
          isLoading: false,
        }));
      }
      }
      if(apiRequestCallId==this.hasUnreadId){
        this.setState({hasUnread:responseJson.has_unread_notifications})
      }
      if(apiRequestCallId==this.readNotificationId){
        let oldNotificationList = this.state.notificationList;
        for (const element of oldNotificationList) {
          element.attributes.is_read = true
        }
        this.setState({hasUnread:false, notificationList: oldNotificationList});
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleCartCountChange = (event: CustomEvent) => {
    this.setState({ cartCount: event.detail.count });
  }
  handleWishlistCountChange = (event: CustomEvent) => {
    this.setState({ wishlistCount: event.detail.count });
  }
  apiCall = (data: APIPayload) => {
    let { contentType, method, endPoint, body, type, token } = data;
    let requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const header = {
      ...(contentType ? { "Content-Type": contentType } : {}),
      token
    };
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        !type ? JSON.stringify(body) : body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  async componentDidUpdate() {
    await this.fetchProfilePic();
    const token = await getStorageData('loginToken');
  }

  async handlerFetch() {
    await this.fetchProfilePic();
  }

  async componentWillUnmount() {
    window.removeEventListener('storage', this.handlerFetch);
    window.removeEventListener('cartCountChange', this.handleCartCountChange as EventListener);
    window.removeEventListener('wishlistCountChange', this.handleWishlistCountChange as EventListener);
    window.removeEventListener("mousedown", this.handleClickOutside);
  }
 
  fetchProfilePic: () => Promise<void> = async () => {
    try {
      const username: string =
          (await getStorageData('profileUsername')) ||
          'USERNAME_TESTING_PURPOSE_ONLY',
        profilePic: string =
          (await getStorageData('profilePic')) || defaultProfilePic;
      if (username && username.split(' ')[0] !== this.state.username) {
        this.setState({
          profilePic: profilePic,
          username: username.split(' ')[0],
        });
      }
      if (profilePic !== this.state.profilePic) {
        this.setState({
          profilePic: profilePic,
        });
      }
    } catch (error) {
      if (this.state.username !== 'USERNAME_TESTING_PURPOSE_ONLY') {
        this.setState({
          username: 'USERNAME_TESTING_PURPOSE_ONLY'
        });
      }
      if (this.state.profilePic !== defaultProfilePic) {
        this.setState({ profilePic: defaultProfilePic });
      }
    }
  };

  navigationToScreen = (route: string) => {
    if(this.props.navigation) {
            const message = new Message(getName(MessageEnum.NavigationMessage));
            message.addData(getName(MessageEnum.NavigationTargetMessage), route);
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            const raiseMessage: Message = new Message(
              getName(MessageEnum.NavigationPayLoadMessage)
            );
            raiseMessage.addData(getName(MessageEnum.NavigationScreenNameMessage), { screenName : "All Courses" });
            message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
            this.send(message)
    } else {
        const a = document.createElement('a');
        a.href = `/${route}`;
        a.click();
    }

  };

  handleSearchValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ search: event.target.value });
  }
  navigateToSearchResultScreen = () => {
    localStorage.setItem('courseListType','search');
    localStorage.setItem('courseListSearchText',this.state.search);
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "SearchCourses");

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationInfoPageMessage), { searchParams: this.state.search });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }

  handleClearSearch = () => {
    localStorage.removeItem("courseListSearchText");
    this.setState({ search: "" });
    this.navigationToScreen("Categoriessubcategories");
  }

  toggleDropdown = () => {
    if(this.state.userToken){
    if(this.state.isDropdownVisible){
      this.markAsRead();
    }
    this.setState((prevState) => ({
      isDropdownVisible: !prevState.isDropdownVisible,
    }));
    this.setState({isHovering:false})
  }
  };
  handleClickOutside(event: MouseEvent) {
    if(this.state.userToken){

    if (this.dropdownRef.current && !this.dropdownRef.current.contains(event.target as Node)) {
      if(this.state.isDropdownVisible){
        this.markAsRead();
      }
      this.setState({ isDropdownVisible: false });
    }
  }
  }
  markAsRead = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.state.userToken
    };

    const notifications = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.readNotificationId = notifications.messageId;

    notifications.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_push_notifications/push_notifications/mark_all_as_read`
    );

    notifications.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    notifications.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(notifications.id, notifications);
  };
  notficationApi=()=>{
    if (!this.state.hasMore || this.state.isLoading) return;

    this.setState({ isLoading: true });
  
  
    const header = {
      "Content-Type": "application/json",
      token: this.state.userToken
    };

    const notifications = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.notificationId = notifications.messageId;

    notifications.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_push_notifications/push_notifications?page=${this.state.currentPage}&per_page=${this.state.per_page}`
    );

    notifications.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    notifications.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(notifications.id, notifications);
  }
  hasUnreadMsg=()=>{
    const header = {
      "Content-Type": "application/json",
      token: this.state.userToken
    };

    const notifications = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.hasUnreadId = notifications.messageId;

    notifications.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_push_notifications/push_notifications/has_unread_notifications`
    );

    notifications.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    notifications.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(notifications.id, notifications);
  }
  handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
  
    if (scrollTop + clientHeight >= scrollHeight && !this.state.isLoading) {
      this.notficationApi();
    }
  };
  
  
  convertDateFormat = (dateString: string) => {
    const givenDate = parseISO(dateString); // Parse the given date
  const currentDate = new Date(); 
  const differenceInDaysValue = differenceInDays(currentDate, givenDate);
    
    const pastMoment = moment(givenDate).subtract(0, 'days');
    let finalDateString = '';
    if (differenceInDaysValue <= 7) {
      finalDateString = pastMoment.fromNow().toString()
    }else{
      finalDateString =  format(givenDate, 'dd MMM yy')
    }
    return finalDateString;
  }

  handleNotificationPermission = async () => {
    const prevfcmToken = await getStorageData("fcmtoken");
      await requestPermission();
      const fcmToken = await getStorageData("fcmtoken");
      if(fcmToken && fcmToken !== prevfcmToken){
        this.addDeviceIdApiCallId = this.apiCall(
          {
            endPoint: "account_block/accounts/add_device_id",
            method: "POST",
            token: this.state.userToken,
            body: {
              add_device_id: fcmToken,
            },
          }
        );
      }
      registerServiceWorker();
    }
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
