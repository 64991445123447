
// Customizable Area Start
import React from "react";
import {
    Box,
    Button,
    MenuItem,
    Modal,
    Select,
    TextField,
    Typography,
    styled,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
const drawerWidth = 250;
const closeDrawer = 0;
import MainLayout from "../../../components/src/MainLayout.web";
import NotificationAlertMessage from "../../../components/src/NotificationAlertMessage";
import { Wrapper } from "./AddAdminCourses.web";
import CoursesSidebar from "./components/CoursesSidebar.web";
import { PiPen } from "react-icons/pi";
import { FaRegTrashAlt, FaChevronDown, FaStar } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CourseReviewsAndRatingsController, {
    Props,
    TestimonialData,
    configJSON,
} from "./CourseReviewsAndRatingsController.web";
import {  ReviewFieldBox } from "./AdminReviewsAndRatings.web";
import { CourseClassesFieldBox, CourseClassesFieldControlBox } from "./AdminCourseClasses.web";
const menuItems = [
    {
        text: "Course Edit",
        path: configJSON.courseEditPath,
        active: false
    },
    {
        text: "Course Chapter",
        path: configJSON.courseChapterPath,
        active: false
    },
    {
        text: "Course Classes",
        path: configJSON.courseClassesPath,
        active: false
    },
    {
        text: "Reviews & Ratings",
        path: configJSON.courseRatingsPath,
        active: true
    },
];
// Customizable Area End

export default class CourseReviewsAndRatings extends CourseReviewsAndRatingsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    findOutRatingFanStar(ratingStar: number){
        let ratingStarArray = [];
        for (let index = 1; index < 6 ; index++) {
          if(index <= ratingStar){
          ratingStarArray.push({rating:index, type:'selected'})
        }
       }
        return (
          <div style={{display:'flex', flexDirection:'row', alignSelf:'center'}}>
            {ratingStarArray.map(()=>{
              return(
                <div style={{ marginRight: "5px" }}>
                  <FaStar fontSize={14} color={'#0F172A'} />
                </div>
              )
            })}
          </div>
        )
      }

    renderStars = (count: number) => {
        const stars = [];
        for (let i = 0; i < count; i++) {
            stars.push(<span key={i}>&#9733;</span>);
        }
        return stars;
    };

    renderRatingSelect = () => {
        return (
            <ReviewFieldControlBox>
                <Typography className="input_txt">Rating</Typography>
                <SelectBox
                    id="demo-customized-select-native"
                    disableUnderline
                    IconComponent={FaChevronDown}
                    data-test-id="ratingSelectTestId"
                    onChange={(event) => this.handleRatingSelectChange(event.target.value)}
                    value={this.state.rating}
                >
                    <MenuItem aria-label="None" value="" disabled />
                    {this.state.ratingListData.map((rating: { id: string }) => {
                        return (
                            <MenuItem key={rating.id} value={rating.id}>
                                {rating.id}
                            </MenuItem>
                        );
                    })}
                </SelectBox>
            </ReviewFieldControlBox>
        )
    };

    renderAddReviewModal = () => {
        return (
            <>
                <AddReviewModal
                    style={{ backgroundColor: "rgba(255, 255, 255, .4)" }}
                    data-test-id="AddReviewModalId"
                    open={this.state.openAddReviewModal}
                    onClose={this.handleCloseAddReviewModal}
                >
                    <AddReviewModalView>
                    <NotificationAlertMessage
                            alertType={this.state.alertType}
                            msg={this.state.alertMsg}
                            onClose={this.oncloseAlert}
                            isOpen={this.state.isAlert}
                            data-test-id="notificationAlertMessage"
                        />
                        <AddReviewModalBox>
                            <ModalHeadingText>{configJSON.addReviewsAndRatingsText}</ModalHeadingText>
                            <RxCross2 onClick={() => this.handleCloseAddReviewModal()} style={{ height: "25px", width: "25px" }} />
                        </AddReviewModalBox>
                        <CourseClassesFieldBox>
                            <CourseClassesFieldControlBox>
                                <Typography className="input_txt">{configJSON.courseFieldText}</Typography>
                                <div
                                    style={{
                                        display: "flex",
                                        width: "calc(100% - 160px)",
                                        flexWrap: "wrap",
                                    }}
                                >
                                    <DisableTextInput>
                                        <Typography style={{
                                            alignSelf: 'center', color: '#979797', fontSize: "16px",
                                            fontFamily: "Inter",
                                            fontWeight: 400,
                                        }}>{this.state.courseName}</Typography>
                                    </DisableTextInput>
                                </div>
                            </CourseClassesFieldControlBox>
                        </CourseClassesFieldBox>
                        <CourseClassesFieldBox>
                            <CourseClassesFieldControlBox>
                                <Typography className="input_txt">{configJSON.studentNameFieldText}</Typography>
                                <div
                                    style={{
                                        display: "flex",
                                        width: "calc(100% - 160px)",
                                        flexWrap: "wrap",
                                    }}
                                >
                                    <CourseChapterInputBox
                                        variant="outlined" fullWidth
                                        data-test-id="inputStudentID"
                                        value={this.state.studentName}
                                        onChange={(e) =>
                                            this.setStudentName(e)
                                          }
                                    />
                                </div>
                            </CourseClassesFieldControlBox>
                        </CourseClassesFieldBox>
                        <ReviewFieldBox>
                            {this.renderRatingSelect()}
                        </ReviewFieldBox>
                        <ReviewFieldControlBox>
                            <Typography className="input_txt">{configJSON.commentFieldText}</Typography>
                            <MultiLineTextField
                                data-test-id="commentInputTestId"
                                variant="outlined"
                                multiline
                                name="comment"
                                onChange={this.handleCommentChange}
                                value={this.state.comment}
                            />
                        </ReviewFieldControlBox>
                        <ButtonBox>
                            {this.state.isUpdate ?
                                <Button variant="contained" className="reset_btn"
                                    onClick={() => this.handleCloseAddReviewModal()}
                                >
                                    {configJSON.cancelBtnText}
                                </Button> :
                                <Button variant="contained" className="reset_btn"
                                    onClick={this.handleFeildReset}
                                >
                                    {configJSON.resetBtnText}
                                </Button>}
                            {this.state.isUpdate ?
                                <Button
                                    data-test-id="updateButtonTestId"
                                    onClick={this.handleUpdatePostCourseReview}
                                    disabled={this.state.loader}
                                    variant="contained" className="create_btn">
                                    {configJSON.updateBtnText}
                                </Button> :
                                <Button
                                    data-test-id="addButtonTestId"
                                    onClick={this.handlePostCourseReview}
                                    disabled={this.state.loader}
                                    variant="contained" 
                                    className="create_btn">
                                    {configJSON.addReviewsAndRatingsBtnText}
                                </Button>}
                        </ButtonBox>
                    </AddReviewModalView>
                </AddReviewModal>
            </>
        )
    };

    renderDeleteReviewsAndRatingsModal = () => {
        return (
            <>
                <ReviewsAndRatingsDeleteModal
                    style={{ backgroundColor: "rgba(255, 255, 255, .4)" }}
                    data-test-id="ReviewDeleteModalId"
                    open={this.state.openDeleteReviewModal}
                    onClose={this.handleCloseDeleteReviewsAndRatingsModal}
                >
                    <ReviewsAndRatingsModalView>
                        <ReviewsAndRatingsDeleteText component={"p"}>
                            Do you want to delete this review and rating?
                        </ReviewsAndRatingsDeleteText>
                        <ReviewsAndRatingsModalButton>
                            <ReviewsAndRatingsDeleteButton data-test-id="deleteBtnTestId"
                                onClick={() => this.handleDeleteReviewsAndRatings(this.state.deleteReviewId)}
                            >
                                Delete
                            </ReviewsAndRatingsDeleteButton>
                            <ReviewsAndRatingsCancelButton data-test-id="cancelBtnTestId"
                                onClick={() => this.handleCloseDeleteReviewsAndRatingsModal()}
                            >
                                Cancel
                            </ReviewsAndRatingsCancelButton>
                        </ReviewsAndRatingsModalButton>
                    </ReviewsAndRatingsModalView>
                </ReviewsAndRatingsDeleteModal>
            </>
        )
    };

    renderTableBody = (item: TestimonialData, index: number) => {
        return (
            <>
                <tr>
                    <td style={{ textAlign: "center"}}>{item.attributes.serial_number}</td>
                    <td style={{ textAlign: "left" }}>
                        {item.attributes.name}
                    </td>
                    <td style={{ textAlign: "left" }}>
                        {item.attributes.user_name.full_phone_number}
                    </td>
                    <td style={{ textAlign: "left" }}>
                        {item.attributes.user_name.email}
                    </td>
                    <td style={{ textAlign: "left" }}>
                        {item.attributes.rating
                            ? this.findOutRatingFanStar(item.attributes.rating)
                            : "----"}
                    </td>
                    <td style={{ textAlign: "left" }}>
                        {item.attributes.comment.length > 50
                            ? `${item.attributes.comment.slice(0, 50)}...`
                            : item.attributes.comment || "----"}
                    </td>
                    <td style={{ textAlign: "left" }}>
                        <CheckBtn className="switchs">
                            <input
                                type="checkbox"
                                checked={item.attributes.status}
                                data-test-id="swithchsStatus"
                                onClick={(e: any) =>
                                    this.handleToggleTestimonialList(item)
                                  }
                            />
                            <span className="slider round"></span>
                        </CheckBtn>
                    </td>
                    <td>
                        <Box className="desc_edit_icon_box">
                            <PiPen
                                data-test-id={'editIconTestId'}
                                className="desc_edit_icon"
                                color={"black"}
                                size={20}
                                onClick={() => this.handleShowReview(item)}
                            />
                            <FaRegTrashAlt
                                data-test-id={'deleteIconTestId'}
                                className="desc_edit_icon"
                                color={"black"}
                                size={17}
                                onClick={() => this.handleOpenDeleteReviewsAndRatingsModal(item.id)}
                            />
                        </Box>
                    </td>
                </tr>
                <Box className="table_divider" style={webStyles.tableDivide} />
            </>
        );
    };

    renderTable = () => {
        return (
            <Box>
                <TableStyle>
                    <thead>
                        <tr>
                            <th>
                                <Box className="th_box" style={{ justifyContent: "center" }}>
                                    <Typography className="title_th" >S.no</Typography>
                                </Box>
                            </th>
                            {["Name", "Phone", "Email", "Rating", "Reviews", "Status", "Action" ].map(
                                (header, index) => (
                                    <th key={index.toString()}>
                                        <Box className="th_box" style={{ justifyContent: header == 'Action' ? "center" : "start" }}>
                                            <Typography className="title_th">{header}</Typography>
                                        </Box>
                                    </th>
                                )
                            )}
                        </tr>
                    </thead>
                    <span style={webStyles.lineDivide} />
                    <TableBody>
                        {this.state.testimonialsList.length > 0 && this.state.testimonialsList.map((item, index) =>
                            this.renderTableBody(item, index)
                        )}
                    </TableBody>
                </TableStyle>
                {this.state.testimonialsList.length > 0 ? 
                <PaginationBox
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        marginTop: "20px"
                    }}
                >
                    <Pagination
                        data-test-id="pageChangebutton"
                        count={this.state.totalPages}
                        shape="rounded"
                        boundaryCount={1}
                        page={this.state.pageNo}
                        siblingCount={0}
                        onChange={this.handlePageChange}
                    />
                </PaginationBox>
                :<Box style={{ height: '100vh', justifyContent: 'center', alignItems: 'center', display: 'flex', overflow: 'hidden' }}>
                <Typography style={{ color: 'black' }}>{configJSON.recordNotFound}</Typography>
              </Box>}
            </Box>
        );
    };

    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <MainLayout
                data-test-id="mainLayoutEvent"
                handleSearch={(e: any) => this.handleReviewsAndRatingsSearch(e)}
                isLoading={this.state.isLoading}
                title={"Reviews & Ratings"}
                actionButtons={[
                    {
                        icon: <AddCircleOutlineIcon />,
                        iconType: "jsxElement",
                        onClick: () => this.addReviewModal(),
                        text: "Create Review",
                        testId: "createReviewButtonId"
                    }
                    ]}
                isStudent={false}
            >
                <NotificationAlertMessage
                    alertType={this.state.alertType}
                    msg={this.state.alertMsg}
                    onClose={this.oncloseAlert}
                    isOpen={this.state.isAlert}
                    data-test-id="notificationAlertMessage"
                />
                {this.renderAddReviewModal()}
                {this.renderDeleteReviewsAndRatingsModal()}
                <Wrapper>
                    <CoursesSidebar data-test-id="coursesSidebar" menuItems={menuItems} onItemClick={this.handleSidebarItemClick} />
                    <main className="table_content">{this.renderTable()}</main>
                </Wrapper>
            </MainLayout>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const PaginationBox = styled(Box)({
    "& .viewall_txt": {
        color: "#206575",
        fontSize: "0.75rem",
        cursor: "pointer"
    },
    "& .MuiPaginationItem-page.Mui-selected": {
        backgroundColor: "#206575",
        color: "#fff"
    }
});

const webStyles = {
    showDrawer: {
        width: `calc(100% - ${drawerWidth}px)`
    },
    categoryImgName: {
        display: "inline-flex",
        gap: "1rem",
        alignItems: "center",
        width: "100%",
        height: "100%"
    },
    checkboxMain: {
        display: "flex",
        width: "100%",
        justifyContent: "center"
    },
    lineDivide: {
        height: "20px",
        display: "inline-block"
    },
    tableDivide: {
        height: "10px"
    },
    input: {
        width: "20px",
        height: "20px",
        border: "3px solid black",
        background: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
};

const AddReviewModal = styled(Modal)({
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
});

const AddReviewModalView = styled(Box)({
    width: "651px",
    height: "450px",
    borderRadius: '18px',
    padding: "10px 30px",
    overflowY: "auto",
    backgroundColor: '#ffffff',
    margin: "20px",
    outline: "none",
    boxShadow: "0px 0px 3px px transparent",
    display: "flex",
    flexDirection: "column"
});

const AddReviewModalBox = styled(Box)({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    paddingTop: 10,
    paddingBottom: 22
});

const ModalHeadingText = styled(Typography)({
    fontStyle: "normal",
    fontSize: "18px",
    fontFamily: "Inter",
    fontWeight: 700,
    color: "#000",
    letterSpacing: "-0.12px",
    lineHeight: "26px",
});

const ReviewFieldControlBox = styled(Box)({
    justifyContent: "flex-start",
    display: "flex",
    alignItems: "center",
    margin: "10px 0",
    "& .file_upload_box": {
        width: "490px",
        height: "44px",
        color: "#fff",
        border: "1px solid black",
        display: "flex",
        justifyContent: "space-between",
        borderRadius: "4px",
        alignItems: "center",
        borderColor: "#D4D4D4",
        "& .lable_txt": {
            alignItems: "center",
            display: "flex",
            height: "90%",
            marginRight: "2px",
            borderRadius: "4px",
            backgroundColor: "#3F6474",
            fontSize: "12px",
            justifyContent: "center",
            padding: "0px 30px",
        },
        "& .app_icon_txt": {
            color: "#A9A9A9",
            fontSize: "13px",
            wordSpacing: "-2px",
            marginLeft: "10px",
        },
    },
    "& .input_txt": {
        width: "160px",
        fontFamily: "Inter",
        fontStyle: "normal",
        textTransform: "capitalize",
        color: "#192252",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px"
    },
    "@media (max-width:875px)": {
        alignItems: "start",
        flexDirection: "column",
        "& .input_txt": {
            width: "100%",
            marginBottom: "8px",
        }
    },
});

const SelectBox = styled(Select)({
    "&.MuiInputBase-root": {
        width: "490px",
        borderRadius: "4px",
        border: "1px solid #EFF3FA",
        padding: "0px 5px",
    },
    "& .MuiNativeSelect-icon": {
        right: "6px",
        top: "calc(50% - 7px);",
    },
    "& .MuiInputBase-input": { padding: "10px 0px 10px 8px" },
    "&  .MuiSelect-icon": {
    right: "10px",
    color:'#0F172A'
  },
});

const MultiLineTextField = styled(TextField)({
    width: '75%',
    '& .MuiInputBase-input': { 
        boxSizing: 'border-box',
        height: '90px',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#EFF3FA', // Border color when unfocused
        },
      '&.Mui-focused fieldset': { 
        borderColor: '#3F6474' 
    },
    '& .MuiInputBase-inputMultiline': {
        height: '60px !important',
    },
    },
  });

  export const ButtonBox = styled(Box)({
    justifyContent: "center",
    display: "flex",
    width: "100%",
    margin: "25px 0",
    alignItems: "center",
    "& .create_btn": {
        borderRadius: "0",
        color: "#fff",
        textTransform: "capitalize",
        fontSize: "14px",
        backgroundColor: "#3f6474",
        boxShadow: "none",
        width: "220px",
        fontFamily: "Inter",
        fontWeight: 700,
        marginLeft:'10px',
        height: "40px",
        lineHeight: "22px",
        fontStyle: "normal",
    },
    "& .reset_btn": {
        textTransform: "capitalize",
        borderRadius: "0",
        border: "1px solid #3F6474",
        fontSize: "14px",
        backgroundColor: "#fff",
        width: "108px",
        padding: "10px 60px",
        color: "#3f6474",
        marginLeft: "20px",
        fontFamily: "Inter",
        height: "40px",
        fontStyle: "normal",
        lineHeight: "22px",
        boxShadow: "none",
        fontWeight: 700,
    },
    "@media (max-width:460px)": {
        "& .create_btn": {
            padding: '5px 48px',
            height: '40px',
        },
        "& .reset_btn": {
            padding: '5px 50px',
            height: '40px',
        },
    },
    "@media (max-width:400px)": {
        "& .create_btn": { width: '100%' },
        flexDirection: 'column',
        "& .reset_btn": { marginBottom: '10px', width: '100%' }
    },
});

export const CourseChapterInputBox = styled(TextField)({
    width: "490px",
    "& .MuiOutlinedInput-root": {
    '& fieldset': {
        borderColor: '#EFF3FA', // Border color when unfocused
    }, 
    "&.Mui-focused fieldset": { borderColor: "#3F6474" },
 },
    "& .MuiOutlinedInput-input": { padding: "0px 14px" },
    "& .MuiInputBase-input": { height: "44px" },
});
export const ReviewsAndRatingsDeleteModal = styled(Modal)({
    width: "100%",
    display: "flex",
    justifyContent: "center",
    height: "100%",
    alignItems: "center",
});

export const ReviewsAndRatingsModalView = styled(Box)({
    height: "150px",
    width: "400px",
    borderRadius: '5px',
    outline: "none",
    backgroundColor: '#ffffff',
    display: "flex",
    margin: "20px",
    boxShadow: "0px 0px 3px px transparent",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
});

export const ReviewsAndRatingsDeleteText = styled(Box)({
    width: "100%",
    height: "37px",
    fontWeight: 600,
    fontSize: "16px",
    textAlign: 'center'
});

export const ReviewsAndRatingsCancelButton = styled("button")({
    width: "145px",
    height: "40px",
    color: "#206575",
    fontSize: "16px",
    border: "1px solid #206575",
    backgroundColor: "white",
    marginLeft: '10px'
});

export const ReviewsAndRatingsModalButton = styled(Box)({
    display: "flex",
});

export const ReviewsAndRatingsDeleteButton = styled("button")({
    width: "145px",
    border: "0",
    height: "40px",
    color: "white",
    backgroundColor: "#FD7101",
    fontSize: "16px",
    fontWeight: 500
});

export const TableStyle = styled("table")({
    overflow: "scroll",
    borderTop: "2px solid black",
    color: "black",
    minWidth: "670px",
    position: "relative",
    marginTop: "10px",
    width: "100%",
    borderSpacing: "0",
  
    "& thead": {
      width: "100%",
      padding: "10px 5px",
      height: "50px",
      borderCollapse: "initial",
    },
    "& th": {
      fontWeight: "600",
      borderBottom: "2px solid black",
  
      "& .th_box": {
        display: "flex",
        alignItems: "center",
        padding: "10px 10px",
  
        "& .title_th": {
          color: "#000",
          lineHeight: "18px",
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: "0.625rem",
        },
        "& .filter_icon": {
          marginLeft: "5px",
          height: "15px",
          cursor: "pointer",
          width: "15px",
          color: "#000",
          borderRadius: 99,
          padding: 1,
          "&:hover": {
            background: "rgba(0, 0, 0, 0.13)",
          },
        },
        '& .active': {
          width: 20,
          height: 20,
          background: "rgba(0, 0, 0, 0.13)"
        }
      },
    },
    "& td": {
      "& .desc_checkbox": {
        width: "100%",
        justifyContent: "center",
        display: "flex",
      },
      "& .desc_icon_box": {
        textAlign: "left",
        "& .desc_sort_icon": {
          backgroundColor: "lightgray",
          width: "20px",
          padding: "3px",
          height: "20px",
        },
      },
  
      "& .desc_edit_icon_box": {
        display: "flex",
        justifyContent: "center",
        width: "100%",
  
        alignItems: "center",
        "& .desc_edit_icon": {
          margin: "0 10px",
          cursor: "pointer",
        },
      },
      "& .desc_img": {
        height: "32px",
        width: "32px",
      },
    },
    "&.table_divder": {
      height: "10px",
    },
  });
  export const TableBody = styled("tbody")({
    "& tr": {
      backgroundColor: "#f5f5f7",
      borderRadius: "20px",
      height: "50px",
      padding: "45px",
      margin: "20px",
    },
    "& td": {
      fontWeight: "400",
      fontSize: "0.625rem",
    //   textAlign: "center",
      padding: "0px 10px",
    },
    "& td button": {
      border: "none",
    },
  });
  export const DisableTextInput = styled(Box)({
    display: 'flex',
    width: "490px",
    height: "44px",
    border: '1px solid #EFF3FA',
    padding: "0px 14px",
    borderRadius: '5px'
  });
  export const CheckBtn = styled("label")({
    "&.switchs": {
      "& input": {
        opacity: 0,
        width: 0,
        height: 0,
      },
      marginTop: "5px",
      width: "36px",
      position: "relative",
      height: "19px",
      display: "inline-block",
    },
    "& .slider": {
      top: 0,
      left: 0,
      right: 0,
      position: "absolute",
      cursor: "pointer",
      bottom: "-1px",
      backgroundColor: "#ccc",
      WebkitTransition: ".4s",
      transition: ".4s",
      borderRadius: "20px",
    },
    "& .slider:before": {
      left: "1px",
      bottom: "1px",
      backgroundColor: "white",
      height: "18px",
      borderRadius: "50%",
      WebkitTransition: ".4s",
      position: "absolute",
      width: "18px",
      transition: ".4s",
      content: '""',
    },
    "& input:checked + .slider:before": {
      left: "-10px",
    },
    "& input:checked + .slider": {
      backgroundColor: "#708328",
    },
  });
// Customizable Area End