import React, { CSSProperties } from "react";

// Customizable Area Start
import {
  Box,
  Chip,
  Button,
  styled,
  Fade,
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { FaRegCheckCircle } from "react-icons/fa";
import { IoMdCloseCircleOutline } from "react-icons/io";
import CircularProgress from "@material-ui/core/CircularProgress";
import MyCashController, {
  Datum,
  DiscountType,
  Props
} from "./MyCashController.web";
import NotificationAlertMessage from "../../../components/src/NotificationAlertMessage";
import { LiaRupeeSignSolid } from "react-icons/lia";
import UserSidebarMenu from "../../../components/src/UserSideBarMenu";
import { userProfileStyles } from "../../user-profile-basic/src/UserProfile.web";
import Footer from "../../../components/src/Footer.web";
import HeaderWeb from "../../../components/src/Header.web";
import moment from "moment";
let theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "'Inter', monospace"
    },
    body1: {
      fontFamily: "'Inter', monospace"
    }
  }
});
theme = responsiveFontSizes(theme);

// Customizable Area End

export default class MyCash extends MyCashController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.closemodal = this.closemodal.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  closemodal() {
    this.oncloseAlert();
  }

  closeDialog() {
    this.onCloseDialog();
  }

  async copyTextToClipboard(id: number, code: string | undefined) {
    try {
      await navigator.clipboard.writeText(code || "");
      this.setState({
        isAlert: true,
        alertMsg: `${id}`,
        alertType: "success"
      });
    } catch (error) {
      this.setState({
        isAlert: true,
        alertMsg: "Can't copy code to clipboard: " + code,
        alertType: "error"
      });
    }
  }

  refferalUsed(updated: any) {
    this.setState({
      showModal: true,
      updatedDate: updated
    });
  }

  async handleRedeemClick(item: Datum) {
    if (item.is_used) {
      this.refferalUsed(item.updated_at);
    } else {
      const code = item.redeem_code || item.coupon_code || item.discount_code;
      await this.copyTextToClipboard(item.id, code);
    }
  }

  renderList() {
    return (
      <StyledBox style={userProfileStyles.mainLayout}>
        <SideBox>
          <UserSidebarMenu
            userPic={this.state.profilePic}
            fullName={this.state.username}
            pathname={window.location.pathname}
            phoneNumber={"+" + this.state.phoneNumber}
          />
        </SideBox>
        <Box sx={{ ...userProfileStyles.columnLayout, width: "75%" }}>
          <h1 style={{ color: "white" }}>My Cash</h1>
          <div style={webStylesLocal.cashContainer}>
            <div style={webStylesLocal.cashDisplay}>
              <div>
                <LiaRupeeSignSolid style={{ color: "black" }} />
              </div>
              <span>
                <strong>{this.state.totalAmount.toFixed(2)}</strong>
              </span>
            </div>
            <div>
              <p style={{ margin: "7px 0" }}>
                Your total available My Cash units
              </p>
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell style={webStylesLocal.tableHeader}>Date</TableCell>
                  <TableCell style={webStylesLocal.tableHeader}>
                    Amount (INR)
                  </TableCell>
                  <TableCell style={webStylesLocal.tableHeader}>
                    Expiry Date
                  </TableCell>
                  <TableCell style={webStylesLocal.tableHeader}>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.couponsAndDiscountsList.map((item, index) => {
                  const points = Number(item.discount || item.points);

                  return (
                    <>
                      <TableRow key={index} style={{ borderBottom: "none" }}>
                        <TableCell>
                          <Chip
                            label={item.label}
                            style={{
                              textTransform: "capitalize",
                              background: "#2a9e6d",
                              borderRadius: 10,
                              color: "white",
                            }}
                            variant="default"
                          />
                        </TableCell>
                        <TableCell style={webStylesLocal.tableCell}>
                          {item.get_created_at}
                        </TableCell>
                        <TableCell style={webStylesLocal.tableCell}>
                          {item.discount_type === DiscountType.Percentage
                            ? points
                            : points.toFixed(2)}
                          {item.discount_type === DiscountType.Percentage
                            ? "%"
                            : ""}
                        </TableCell>
                        <TableCell
                          style={{
                            ...webStylesLocal.tableCell,
                            color: item.is_expire ? "#EE676D" : ""
                          }}
                        >
                          {item.get_expiry_date}
                          <p
                            style={{
                              margin: "0px",
                              fontSize: "12px"
                            }}
                          >
                            {item.is_expire && "Expired"}
                          </p>
                        </TableCell>
                        <TableCell style={webStylesLocal.tableCell}>
                          <RedeemButton
                            variant="outlined"
                            data-test-id="redeemButtonTestId"
                            onClick={async () =>
                              await this.handleRedeemClick(item)
                            }
                            style={{
                              borderRadius: 50,
                              color:
                                item.is_used && item.label === "Referral Points"
                                  ? "#ccc"
                                  : "",
                              borderColor:
                                item.is_used && item.label === "Referral Points"
                                  ? "#ccc"
                                  : ""
                            }}
                            disabled={
                              item.is_expire ||
                              (item.is_used && item.label !== "Referral Points")
                            }
                          >
                            {item.is_used ? "Redeemed" : "Redeem Now"}
                          </RedeemButton>
                        </TableCell>
                      </TableRow>
                      <TableRow style={{ padding: 0, margin: 0 }}>
                        <TableCell
                          colSpan={5}
                          style={{ padding: 0, margin: 0 }}
                        >
                          <Fade
                            in={
                              this.state.isAlert &&
                              this.state.alertMsg === `${item.id}`
                            }
                            data-test-id="fadeAlertTestId"
                            unmountOnExit
                            mountOnEnter
                            style={{ marginBottom: "10px", padding: "0px" }}
                          >
                            <Alert
                              variant="filled"
                              severity="success"
                              iconMapping={{
                                success: <FaRegCheckCircle fontSize="inherit" />
                              }}
                              style={{
                                backgroundColor: "#EDFEDA",
                                color: "#09A06D",
                                alignItems: "center",
                                paddingLeft: "10px"
                              }}
                            >
                              Awesome! Your Redeem Code has been copied! Put it
                              in the 'Coupon' field while checkout.
                            </Alert>
                          </Fade>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
            <Dialog
              open={this.state.showModal}
              onClose={this.closeDialog}
              PaperProps={{
                style: {
                  padding: "20px",
                  borderRadius: "10px"
                }
              }}
            >
              <DialogTitle
                style={{
                  padding: "0",
                  textAlign: "right"
                }}
              >
                <IconButton
                  aria-label="close"
                  onClick={this.closeDialog}
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0
                  }}
                >
                  <IoMdCloseCircleOutline />
                </IconButton>
              </DialogTitle>
              <DialogContent
                style={{
                  textAlign: "center"
                }}
              >
                <Typography variant="body1">
                  {this.state.alertType === "error"
                    ? this.state.alertMsg
                    : "You have redeemed this code on"}
                </Typography>
                <Typography
                  variant="h6"
                  style={{
                    color: "#09A06D",
                    fontWeight: "bold",
                    marginTop: "10px",
                    fontSize: "14px"
                  }}
                >
                  {moment(new Date(this.state.updatedDate)).format(
                    "DD MMMM YYYY , HH:mm:ss"
                  )}
                </Typography>
              </DialogContent>
            </Dialog>
            <div style={{ display: "flex", padding: "1rem" }}>
              {this.state.isLoading ? (
                <CircularProgress
                  style={{ margin: "auto", color: "#2a9e6d" }}
                />
              ) : (
                <Button
                  data-test-id="loadMoreButtonTestId"
                  variant="contained"
                  style={{
                    background: "#2a9e6d",
                    margin: "auto",
                    color: "white",
                    display: this.state.loadMoreDisabled ? "none" : "unset"
                  }}
                  disabled={this.state.loadMoreDisabled || this.state.isLoading}
                  onClick={() => this.loadMore()}
                >
                  Load More
                </Button>
              )}
            </div>
          </div>
        </Box>
      </StyledBox>
    );
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <NotificationAlertMessage
          alertType={this.state.alertType}
          msg={this.state.alertMsg}
          onClose={this.closemodal}
          invisible
          isOpen={this.state.isAlert}
        />
        <HeaderWeb id={""} navigation={this.props.navigation} />
        <main
          style={{
            overflowX: "scroll",
            maxWidth: "1920px",
            width: "100%"
          }}
        >
          <div
            style={{
              background:
                "linear-gradient(180deg, rgba(60,116,129,1) 100px, rgba(255,255,255,1) 100px)",
              padding: "25px 10px"
            }}
          >
            {this.renderList()}
          </div>
          <Footer navigation={this.props.navigation} id={""} />
        </main>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const RedeemButton = styled(Button)({
  border: "1px solid #FF8256",
  color: "#FF8256",
  "&:disabled": {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    color: "rgba(0, 0, 0, 0.26)"
  },
  "&.Mui-disabled": {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    color: "rgba(0, 0, 0, 0.26)"
  }
});
const StyledBox = styled(Box)({
  maxWidth: 1440,
  gap: "20px",
  width: 'calc(100% - 55px)',
  margin: '0 auto',
  display: 'flex',
  '@media (min-width: 600px) and (max-width: 1299px)' :{
    margin: '0px 32px'
  },
  '@media (min-width: 1300px) and (max-width: 1440px)' :{
    margin: '0px 55px'
  }
})

const SideBox=styled(Box)({
  width:'15%',
  "@media only screen and (max-width: 1598px)": {
    width:'18%'
  },
  "@media only screen and (max-width: 1525px)": {
    width:'20%'
  },
  "@media only screen and (max-width: 1366px)": {
    width:'22%'
  },
  "@media only screen and (max-width: 1175px)": {
    width:'25%'
  },
  "@media only screen and (max-width: 1070px)": {
    width:'27%'
  },
  "@media only screen and (max-width: 1000px)": {
    width:'30%'
  },
  "@media only screen and (max-width: 760px)": {
    width:'35%'
  },
  "@media only screen and (max-width: 677px)": {
    width:'37%'
  },
  "@media only screen and (max-width: 652px)": {
    width:'38%'
  },
})
const webStylesLocal: Record<string, CSSProperties> = {
  bundleCourseMainLayout: {
    width: "97%",
    margin: "0 auto",
    maxWidth: "1024px"
  },
  adminLayout: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    alignItems: "center"
  },
  listItem: {
    display: "inline-flex",
    justifyContent: "space-between",
    width: "100%",
    boxSizing: "border-box",
    padding: "1rem 0",
    alignItems: "center"
  },
  listItemText: {
    display: "flex",
    flexDirection: "column",
    width: "25%"
  },
  cashContainer: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "12px",
    padding: "1rem 1.5rem",
    background: "#F4F4F4"
  },
  cashDisplay: {
    display: "inline-flex",
    color: "#366A77",
    fontSize: "24px",
    gap: "0.2rem",
    alignItems: "center"
  },
  tableHeader: {
    color: "#939393",
    fontWeight: "bold",
    textAlign: "center"
  },
  tableCell: {
    textAlign: "center"
  },
  disabled: {
    color: "#999",
    borderColor: "#ccc"
  }
};
// Customizable Area End
