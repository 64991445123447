import React from "react";

// Customizable Area Start
import { Container, Box } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { slider1, slider2, slider3 } from "./assets";
import Carousel from "react-material-ui-carousel";
export interface BannerListData {
  id: number;
  type: string;
  attributes: Attributes;
}

export interface Attributes {
  banner_title: string;
  status: boolean;
  created_at: Date;
  updated_at: Date;
  banner_image: string;
}
// Customizable Area End

import CarouselDisplayController, {
  Props,
  configJSON,
} from "./CarouselDisplayController";

export default class CarouselDisplay extends CarouselDisplayController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderThumbnails() {
    return (
      <Box style={webStyle.indicatorIconWrap} data-test-id='indicatorIconWrap'>
        {this.state.imgData.filter(banner => banner.attributes.status).map((item: BannerListData, index: number) => {
          return (
            <Box
            data-test-id={`Carousel-indicatorIcon-${index}`}
            key={index}
            onClick={() => this.handleThumbnailClick(index)}
            style={{
              ...webStyle.indicatorIcon,
              width: `${index === this.state.index ? "35px" : "15px"}`,
              background: `${index === this.state.index ? "#BB13E5" : "#D7D7D7"}`,
              borderRadius: `${index === this.state.index ? "24px" : "50%"}`,
            }}
          >
            <Box
              style={{
                flex: 1,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
          </Box>
          );
        })}
      </Box>
    );
  }
  // Customizable Area End

  render() {
    console.log(slider1);
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container style={{ maxWidth: "1440px" }}>
          <Carousel
          data-test-id="Carousel"
            animation="slide"
            swipe={true}
            index={this.state.index}
            indicators={false}
            onChange={this.handleChangeCarousel}
            autoPlay={true}
            interval={5000}
          >
            {this.state.imgData.filter(banner => banner.attributes.status).map((item: BannerListData, i: any) => {
              return (
                <Box data-test-id={`Carousel-item-${i}`} key={i} style={{...webStyle.carousel, height: this.getWindowInnerWidth(window.innerWidth)}}>
                  <img data-test-id={`Carousel-img-${i}`} style={{...webStyle.carouselImage,
                       height: this.getWindowInnerWidth(window.innerWidth),
                  }} src={item.attributes.banner_image} />
                </Box>
              );
            })}
          </Carousel>
          {this.renderThumbnails()}
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  carouselImage: {
    width: "100%",
    objectFit: 'fill' as const,
    borderRadius: 24,
    cursor: 'pointer'
  }as React.CSSProperties ,
  carousel: {
    flex: 1,
    overflow: "hidden",
    borderRadius: 24
  },
  indicatorIconWrap: {
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: "center",
    transform: "translateY(75%)",
    marginTop: 16
  },
  indicatorIcon: {
    width: 15,
    height: 15,
    marginLeft: 5,
    marginRight: 5,
    overflow: "hidden",
    display: "flex",
  },
};
// Customizable Area End
